app.controller("GenericDocumentViewerUrlController",
    [
        "$scope", "$rootScope", "$timeout", "GenericDocumentViewerUrlService", "translationFlowClientService", "receiverService", "genericDocumentViewerUrlConfigurationService",
        "sharedSessionService", "portletErrorService", "appConfig", "$sce", "$controller",
        function ($scope,
            $rootScope,
            $timeout,
            GenericDocumentViewerUrlService,
            translationFlowClientService,
            receiverService,
            genericDocumentViewerUrlConfigurationService,
            sharedSessionService,
            portletErrorService,
            appConfig,
            $sce,
            $controller) {

            $controller('BasePluginController', { $scope: $scope });

            $scope.status = "";
            $scope.hasStatus = false;
            $scope.isPdf = false;
            $scope.isImage = false;

            $scope.initialize = function () {

                $scope.configurationModel = genericDocumentViewerUrlConfigurationService.getConfigurationModel($scope.portlet);
                $scope.controllingModel = genericDocumentViewerUrlConfigurationService.getControllingModel();
                $scope.controllingModel.DisplayLoadingBar = false;
                $scope.DocumentViewerViewModel = {};
                $scope.DocumentViewerViewModel.PortletId = $scope.portlet.Id;
                $scope.DocumentViewerViewModel.MimeType = $scope.configurationModel.MimeType;
                $scope.DocumentViewerViewModel.FileName = $scope.configurationModel.FileName;
                $scope.DocumentViewerViewModel.Url = $scope.configurationModel.Content;

                $scope.getDocument();
            };

            $scope.refresh = function () {
                $scope.getDocument();
            };

            $scope.$on("onMaximized",
                function (event, pcPortlets) {
                    if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=100";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onMinimized",
                function (event, pcPortlets) {
                    if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {
                        $scope.path = $scope.path + "#zoom=auto";
                        $scope.setPdfSizes();
                    }
                });

            $scope.$on("onRowSelected",
                function (event, value) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", value)) {
                        $scope.getRowData(value.Data);
                        $scope.populateParametrizedPropertiesIfNeeded();
                        $scope.getDocument();
                    }
                });

            $scope.$on("onRefresh",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh();
                    }
                });

            $scope.getRowData = function (dataValues) {
                $scope.DocumentViewerViewModel.Data = {};
                for (var prop in dataValues) {
                    if (dataValues.hasOwnProperty(prop) &&
                        !angular.isFunction(dataValues[prop]) &&
                        !angular.isObject(dataValues[prop])) {
                        $scope.DocumentViewerViewModel.Data[prop] = dataValues[prop];
                    }
                }
            };

            $scope.populateParametrizedPropertiesIfNeeded = function () {
                $scope.DocumentViewerViewModel.Url = $scope.preparePropertyValue($scope.configurationModel.Content); 
                $scope.DocumentViewerViewModel.FileName = $scope.preparePropertyValue($scope.configurationModel.FileName); 
                $scope.DocumentViewerViewModel.MimeType = $scope.preparePropertyValue($scope.configurationModel.MimeType);
            };

            $scope.preparePropertyValue = function (propertyConfigurationValue) {
                var parameters = $scope.getParametersFromText(propertyConfigurationValue); 
                if (parameters === null) return propertyConfigurationValue; 

                var result = propertyConfigurationValue; 

                parameters.forEach(function (param) {
                    parameter = param.replace("{", "").replace("}", "");
                    result = result.replace(param, $scope.DocumentViewerViewModel.Data[parameter]);
                });

                return result; 
            };

            $scope.getDocument = function () {
                $scope.statusCode = "";
                $scope.fileUrl = null;
                $scope.image = null;
                $scope.hasStatus = false;
                $scope.isPdf = false;
                $scope.isImage = false;

                var url = $scope.DocumentViewerViewModel.Url;
                var mimeType = $scope.DocumentViewerViewModel.MimeType;

                // potlet can not work without url
                if (!url) {
                    $scope.setStatus("EmptyUrl");
                    return;
                }  

                // portlet can not work without mimeType
                if (!mimeType) {
                    $scope.setStatus("EmptyMimeType");
                    return;
                } 

                // url parameters have to be set on this stage
                if ($scope.getParametersFromText(url) !== null) return; 

                // mimeType parameters have to be set on this stage
                if ($scope.getParametersFromText(mimeType) !== null) return;
                    
                    $scope.controllingModel.DisplayLoadingBar = true;
                   
                    GenericDocumentViewerUrlService.getDocument($scope.DocumentViewerViewModel, {}, $scope.portlet.Id)
                        .then(function (result) {
                            var data = result.data;

                            if (result.statusText !== "OK") {
                                if (result.statusText === "FileNotFound") $scope.setStatus(result.statusText);
                                else {
                                    $scope.status = result.statusText;
                                    $scope.hasStatus = true;
                                }
                            }

                            if (data.byteLength > 0) {

                                var file = new Blob([data], { type: $scope.DocumentViewerViewModel.MimeType });

                                if ($scope.DocumentViewerViewModel.MimeType === "application/pdf") {

                                    $scope.isPdf = true;

                                    var path = $.path +
                                        "/" + $.contentDir + "/pdfViewer/web/viewer.html?file=" +
                                        $sce.trustAsResourceUrl(URL.createObjectURL(file)) +
                                        "&lang=" +
                                        sharedSessionService.getLanguageCode();

                                    $scope.fileUrl = path;
                                    $scope.setPdfSizes();

                                } else if ($scope.DocumentViewerViewModel.MimeType.startsWith("image")) {

                                    var binary = "";
                                    var bytes = new Uint8Array(data);
                                    var len = bytes.byteLength;
                                    for (var i = 0; i < len; i++) {
                                        binary += String.fromCharCode(bytes[i]);
                                    }
                                    $scope.image = window.btoa(binary);
                                    $scope.isImage = true;
                                } else {
                                    saveAs(file, $scope.DocumentViewerViewModel.FileName);
                                }
                            }
                        }).catch(angular.noop).finally(
                            function () {
                                $scope.controllingModel.DisplayLoadingBar = false;
                            });
            };

            $scope.setPdfSizes = function () {
                $scope.pdfSizes = {
                    "width": "100%", "height": $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").height() -
                        $("#doc" + $scope.portlet.Id).closest(".portletContainerBody").find("ul")
                            .height() -
                        10 + "px"
                }
            };

            $scope.getParametersFromText = function (text) {
                var parameterTemplate = /({\w+})+/g; // looking for those things -> {some_parameter}
                return text.match(parameterTemplate);
            };

            $scope.setStatus = function (statusCode) {
                $timeout(function () {
                    $scope.status = translationFlowClientService.get(statusCode);
                    $scope.hasStatus = true;
                });
            };

            $scope.initialize();
        }
    ]);