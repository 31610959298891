app.controller('Table_CreateOrEditPortletController',
[
    '$scope', '$uibModalInstance', '$rootScope', 'createOrEditModalService', 'tableConfigurationService', 'data',
    'suggestionService', 'editorService', 'connectorService', 'Guid', 'dataEvalService', 'kendoGridUtilService',
    'appConfig', 'TableService',
    function ($scope,
        $uibModalInstance,
        $rootScope,
        createOrEditModalService,
        tableConfigurationService,
        data,
        suggestionService,
        editorService,
        connectorService,
        Guid,
        dataEvalService,
        kendoGridUtilService,
        appConfig,
        TableService) {

        $scope.connectorService = connectorService;
        $scope.editorService = editorService;

        $scope.panelBarOptions = {
            expandMode: "single",
            select: $scope.expandCollapse
        };

        $scope.model = createOrEditModalService.getModel("Table", data); 
        $scope.configurationModel = tableConfigurationService.getConfigurationModel($scope.model.Properties);

        $scope.enableCrudActions = function () {

            var cfgModel = $scope.configurationModel.WorkFlowModel.CrudWorkflow;

            if (angular.isUndefined(cfgModel) || cfgModel === null) {
                return false;
            }
            if (cfgModel === appConfig.TablePlugin.NO_WORKFLOW) {
                return false;
            }
            if (angular.isUndefined(cfgModel.Id) || angular.isUndefined(cfgModel.Name)) {
                return false;
            }
            return true;
        }
        $scope.onclickEditable = function (dataItem) {
            if (dataItem.IsEditable) {
                dataItem.IsEditable = false;
            } else {
                dataItem.IsEditable = true;
            }
            angular.forEach($scope.configurationModel.Mappers,
                function (rowObject) {
                    if (rowObject.Id == dataItem.Id) {
                        rowObject.IsEditable = dataItem.IsEditable;
                    }
                });
        }

        $scope.draggableHint = function (e) {
            return $('<div class="k-grid k-widget"><table><tbody><tr>' +
                $(e).parent().parent().html() +
                '</tr></tbody></table></div>');
        }


        $scope.colorGrid = {
            editable: true,
            scrollable: false,
            toolbar: [{ name: "create", text: "Add" }],
            columns: [
                {
                    field: "Position",
                    title: "Pos",
                    width: 24,
                    readonly: true,
                    editor: kendoGridUtilService
                        .getDummyEditor('<div class="position glyphicon glyphicon-align-justify">&nbsp;</div>'),
                    template:
                        '<div id="ruleId_#: Position #" kendo-droptarget k-drop="onRuleDrop" kendo-draggable k-hint="draggableHint" data-uid="#: uid #" class="position glyphicon glyphicon-align-justify">&nbsp;</div>'
                },
                {
                    field: "Colour",
                    title: "Colour",
                    width: "50px",
                    editor: kendoGridUtilService.getColorPicker(),
                    template: kendoGridUtilService.getColorTemplate()
                },
                { field: "FirstValue", title: "First Value", width: "150px" },
                {
                    field: "Func",
                    title: "Func",
                    width: "100px",
                    editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                    template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                },
                { field: "SecondValue", title: "Second Value", width: "150px" },
                {
                    command: [
                        {
                            name: "destroy",
                            template:
                                "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                        }
                    ],
                    width: 24
                }
            ],
            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.colorRulesBackground,
                autoSync: true,
                transport: {
                    read: function (o) {
                        o.success($scope.configurationModel.colorRulesBackground);
                    },
                    create: function (o) {
                        var item = o.data;
                        item.Position = $scope.configurationModel.colorRulesBackground.length + 1;
                        $scope.configurationModel.colorRulesBackground.unshift(item);
                        o.success(item);
                    },
                    update: function (o) {
                        o.success();
                    },
                    destroy: function (o) {
                        for (var index = 0; index < $scope.configurationModel.colorRulesBackground.length; index++) {
                            if ($scope.configurationModel.colorRulesBackground[index].Id == o.data.Id) {
                                $scope.configurationModel.colorRulesBackground.splice(index, 1);
                                break;
                            }
                        }
                        o.success();
                    },
                },
                schema: {
                    model: {
                        id: "Position",
                        fields: {
                            Position: { type: "int", defaultValue: 0 },
                            FirstValue: { type: "string" },
                            Func: { type: "string", defaultValue: 1 },
                            SecondValue: { type: "string" },
                            Colour: {
                                type: "string",
                                defaultValue: "#000000",
                                validation: {
                                    required: { message: "Field is required"}  }
                            }

                        }
                    },
                }
            })
        };

	    editorService.createColumnNameComboBox($scope.colorGrid.columns[2], $scope.model);

        $scope.colorGrid.dataSource.fetch(function () {
            $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
        });

        $scope.onRuleDrop = function (e) {

            var id = $(e.draggable.element).attr('id').split('_')[1];

            var target = $scope.colorGrid.dataSource.get(id),
                dest = $(e.dropTarget);

            var dropTargetId = $(e.dropTarget).attr('id').split('_')[1];

            if (dest.is("th")) {
                return;
            }
            dest = $scope.colorGrid.dataSource.get(dropTargetId);

            //not on same item
            if (target.get("id") !== dest.get("id")) {
                //reorder the items
                var tmp = target.get("Position");
                target.set("Position", dest.get("Position"));
                dest.set("Position", tmp);

                $scope.colorGrid.dataSource.sort({ field: "Position", dir: "asc" });
            }
        }

        $scope.onclickHidden = function (dataItem) {
            if (dataItem.IsHidden) {
                dataItem.IsHidden = false;
            } else {
                dataItem.IsHidden = true;
            }
            angular.forEach($scope.configurationModel.Mappers,
                function (rowObject) {
                    if (rowObject.Id == dataItem.Id) {
                        rowObject.IsHidden = dataItem.IsHidden;
                    }
                });
        }

        $scope.onclickUserActions = function (dataItem) {

            if (dataItem.UserActions) {
                dataItem.UserActions = false;
            } else {
                dataItem.UserActions = true;
            }

            angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                function (rowObject) {
                    if (rowObject.Id == dataItem.Id) {
                        rowObject.UserActions = dataItem.UserActions;
                    }
                });
        }

        $scope.onclickAlwaysEnabled = function (dataItem) {

            if (dataItem.AlwaysEnabled) {
                dataItem.AlwaysEnabled = false;
            } else {
                dataItem.AlwaysEnabled = true;
            }
            angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                function (rowObject) {
                    if (rowObject.Id == dataItem.Id) {
                        rowObject.AlwaysEnabled = dataItem.AlwaysEnabled;
                    }
                });
        }

        $scope.querySuggestions = suggestionService.getSuggestions();

        $scope.removeAllMappers = function() {
            var grid = $("#mapperGrid").data("kendoGrid");
            $scope.configurationModel.Mappers = [];
            grid.dataSource.data([]);
        }

        $scope.mapQueryColumns = function () {

            var workflow = _.first($scope.model.Workflows);

            var paginationModel = {
                DataSourceType: $scope.model.DataSourceType,
                Search: []
            };

            if ($scope.model.DataSourceType === 'Database') {
                paginationModel.ConnectorName = $scope.model.Queries[0].ConnectorName;
                paginationModel.Query = $scope.model.Queries[0].Value;
            } else {
                paginationModel.WorkflowId = workflow.WorkflowId;
                paginationModel.OutputVariable = workflow.OutputVariable;
            }
            
            TableService.getTableHeader(paginationModel)
                .then(function (response) {
                    $scope.queryExecutionFailure = false;
                    var grid = $("#mapperGrid").data("kendoGrid");
                    $scope.removeAllMappers();

                    var headers = response.data.Headers;
                    var i, arrLen = headers.length;

                    for (i = 0; i < arrLen; i++) {
                        grid.dataSource.add({
                            DatabaseColumn: headers[i].ColumnCode,
                            ColumnName: headers[i].ColumnCode,
                            Editor: "Text"
                        });
                    }
                }).catch(
                    function (error) {
                        $scope.queryExecutionFailure = true;
                        $scope.failureReason = error.data.ResponseStatus.Message;
                    });                
        }

        $scope.mapperOptions = {
            editable: true,
            scrollable: false,
            toolbar: [
                {
                    name: "create", text: "Add"
                },
                {
                    name: "mapQueryColumns",
                    template: '<button class="k-button" ng-click="mapQueryColumns()">Map All Columns</button>'
                },
                {
                    name: "removeAllMappers",
                    template: '<button class="k-button" ng-click="removeAllMappers()">Remove All Mappers</button>'
                },
                 {
                     name: "errInfo",
                     template:
                         '<span ng-show="hasMappersDuplicated" style="margin-left:10px; font-size:12px;" class="label label-danger">Rows duplicated!</span>'
                 },
                 {
                     name: "errInfo",
                     template: '<span ng-show="queryExecutionFailure" tooltip-placement="top" uib-tooltip="{{failureReason}}" style="margin-left:10px; font-size:12px;" class="label label-danger">There is a problem with query. Please check syntax and/or connection.</span>'
                 }],
            columns: [
                { field: "DatabaseColumn", title: "Datasource column", width: "200px" },
                { field: "ColumnName", title: "Column name", width: "210px" },
                { field: "Width", title: "Width", width: "90px" },
                { field: "Lov", title: "Lov", width: "200px", editor: editorService.createLovComboBox },
                { field: "Editor", title: "Editor", width: "200px", editor: editorService.createEditorDropDownList },
                { field: "Length", title: "Length", width: "100px" },
                {
                    field: "IsEditable",
                    title: "Editable",
                    width: "70px",
                    template:
                        "<input ng-click=onclickEditable(dataItem) type='checkbox' #= IsEditable ? checked='checked' : '' # ></input>"
                },
                {
                    field: "IsHidden",
                    title: "Hidden",
                    width: "70px",
                    template:
                        "<input ng-click=onclickHidden(dataItem) type='checkbox' #= IsHidden ? checked='checked' : '' # ></input>"
                },
                {
                    command: [
                        {
                            name: "destroy",
                            template:
                                "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                        }
                    ],
                    width: 24
                }
            ],

            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.Mappers,
                autoSync: true,
                transport: {
                    read: function (o) {
                        angular.forEach($scope.configurationModel.Mappers,
                            function (mapper) {
                                if (!mapper.Editor) {
                                    mapper.Editor = "Text";
                                }
                            });
                        o.success($scope.configurationModel.Mappers);
                    },
                    create: function (o) {
                        var item = o.data;
                        item.Id = $scope.configurationModel.Mappers.length + 1;
                        item.IsEditable = true;
                        item.Code = Guid.newGuid();
                        $scope.configurationModel.Mappers.unshift(item);
                        o.success(item);
                    },
                    update: function (o) {
                        o.success();
                    },
                    destroy: function (o) {

                        for (var index = 0; index < $scope.configurationModel.Mappers.length; index++) {
                            if ($scope.configurationModel.Mappers[index].Id == o.data.Id) {
                                $scope.configurationModel.Mappers.splice(index, 1);
                                break;
                            }
                        }

                        var dbColumns = $scope.configurationModel.Mappers.map(function(m) {
                            return m.DatabaseColumn;
                        });

                        var duplicates = [];

                        for (var i = 0; i < dbColumns.length; i++) {
                            if ((dbColumns.lastIndexOf(dbColumns[i]) !== i) && (duplicates.indexOf(dbColumns[i]) === -1)) {
                                duplicates.push(dbColumns[i]);
                            }
                        }

                        if (duplicates.length === 0) {
                            $scope.hasMappersDuplicated = false;
                        }
                        o.success();
                    },
                },
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            DatabaseColumn: { type: "string" },
                            ColumnName: { type: "string" },
                            Width: { type: "string" },
                            Editor: { defaultValue: "Text" },
                            Length: { type: "string" },
                            Lov: { type: "string" },
                            IsEditable: { type: "boolean" },
                            IsHidden: { type: "boolean" }
                        }
                    }
                }
            })
        };
        var column = editorService.createColumnNameComboBox($scope.mapperOptions.columns[0], $scope.model);
        var dataSource = editorService.getDdlDataSource($scope.mapperOptions.columns[0], $scope.model);

        /**
        * Overridden - see parent function in Editor Service. 
        * @param {} container 
        * @param {} options 
        * @returns {} 
        */
        column.editor = function (container, options) {
            $('<input required data-text-field="ColumnDescription" data-value-field="ColumnCode" data-bind="value:' + options.field + '"/>')
                .appendTo(container)
                .kendoComboBox({
                    filter: "contains",
                    dataSource: dataSource,
                    open: function () {
                        this.dataSource.data([]);
                        this.dataSource.read();
                    },
                    change: function (e) {
                        if ($("#mapperGrid").length) {
                            var mappers = $("#mapperGrid").data("kendoGrid").dataSource.data();
                            
                            var dbColumns = _.flatMap(mappers, function (x) {
                                return x.DatabaseColumn;
                            });

                            var dbClumnsWithoutDuplicates = _.uniq(dbColumns);
                            var hasMappersDuplicated = dbClumnsWithoutDuplicates.length < dbColumns.length;
                            $scope.hasMappersDuplicated = hasMappersDuplicated;
                        }
                    }
                });
        };

        $scope.isCustomWorkflowsGridInEditMode = function () {
            var inEditMode = $('#customWorkflowsGrid').find('.k-grid-edit-row').length > 0 ? true : false;
            if (inEditMode) {
                return true;
            };
            return false;
        }

        $scope.flowOptions = {
            editable: "inline",
            scrollable: false,
            edit: function (e) {
                //change default action buttons
                 var commandCell = e.container.find("td:last");
                 commandCell.html(
                     "<a class='k-button k-button-icontext k-primary k-grid-update gridButton'><span class='k-icon k-i-checkmark' style='color:#525252'></span></a>" +
                     "<a class='k-button k-grid-cancel gridButton'><span class='k-icon k-i-cancel'></span></a>"
                     );
            },
            toolbar: [{ name: "create", text: "Add" }],
            columns: [
                { field: "DisplayName", title: "Display Name", width: "150px" },
                {
                    field: "UserActions",
                    title: "User actions",
                    width: "70px",
                    template: "<input disabled type='checkbox' #= UserActions ? checked='checked' : '' # ></input>",
                    editor: kendoGridUtilService.makeKendoCheckboxFromValue("UserActions"),
                },
                {
                    field: "CustomFlow",
                    title: "Custom Flow",
                    width: "200px",
                    editor: kendoGridUtilService.makeWorkflowTree("workflows", "Name", "Id"),
                    template: '<span ng-bind="dataItem.CustomFlow.Name"></span>'
                },
                {
                    field: "DatasetName",
                    title: "Workflow input table",
                    width: "150px"
                },
                {
                    field: "AlwaysEnabled",
                    title: "Always Enabled",
                    width: "70px",
					template: "<input disabled type='checkbox' #= AlwaysEnabled ? checked='checked' : '' # ></input>",
                    editor: kendoGridUtilService.makeKendoCheckboxFromValue("AlwaysEnabled"),

                },
                { field: "LeftValue", title: "Left Value", width: "150px" },
                {
                    field: "Func",
                    title: "Func",
                    width: "100px",
                    editor: kendoGridUtilService.makeKendoComboBoxFromList(dataEvalService.Functions, 'Name', 'Id'),
                    template: kendoGridUtilService.getListTemplate(dataEvalService.Functions, 'Name', 'Id', 'Func')
                },
                { field: "RightValue", title: "Right Value", width: "150px" },
                {
                    command: [
                        {
                            name: "edit",
                            template: "<a class='k-button k-button-icontext k-grid-edit gridButton'><span class='k-icon k-i-edit'></span></a>",
                        },
                        {
                            name: "up",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupUp($event)'><span class='k-icon k-i-arrow-up'></span></a>"
                        },
                        {
                            name: "down",
                            template: "<a class='k-button k-button-icontext gridButton' ng-click='groupDown($event)'><span class='k-icon k-i-arrow-down'></span></a>"
                        },
                        {
                            name: "destroy",
                            template: "<div class='k-button k-button-icontext k-grid-delete gridButton'><span class='k-icon k-i-close'></span></div>"
                        }
                    ],
                    width: 24
                }],

            dataSource: new kendo.data.DataSource({
                data: $scope.configurationModel.WorkFlowModel.FlowConfigurations,
                autoSync: false,
                transport: {
                    read: function (o) {
                        o.success($scope.configurationModel.WorkFlowModel.FlowConfigurations);
                    },
                    create: function (o) {
                        var item = o.data;
                        item.Id = $scope.configurationModel.WorkFlowModel.FlowConfigurations + 1;
                        item.Code = Guid.newGuid();
                        $scope.configurationModel.WorkFlowModel.FlowConfigurations.unshift(item);
                        o.success(item);
                    },
                    update: function (o) {
                        o.success();
                    },
                    destroy: function (o) {
                        for (var index = 0;
                            index < $scope.configurationModel.WorkFlowModel.FlowConfigurations.length;
                            index++) {
                            if ($scope.configurationModel.WorkFlowModel.FlowConfigurations[index].Id == o.data.Id) {
                                $scope.configurationModel.WorkFlowModel.FlowConfigurations.splice(index, 1);
                                break;
                            }
                        }
                        o.success();
                    },
                },
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            DisplayName: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"}
                                }
                            },
                            UserActions: { type: "boolean"},
                            CustomFlow: {
                                validation: {
                                    required: true,
                                    customflowvalidation: function (input) {

                                        if (input.is("[name='CustomFlow']")) {
                                            input.attr("data-customflowvalidation-msg", "Field is required");
                                            var  spanFlowName = input.siblings('span');
                                            var spanFlowNameLabel = jQuery(spanFlowName).find('label');
                                            var spanFlowNameLabelClass = spanFlowNameLabel.attr("class");
                                            if (spanFlowNameLabelClass) {
                                                if (spanFlowNameLabelClass.indexOf("label-warning") > -1) {
                                                    return false;
                                                }
                                            }
                                        }

                                        return true;
                                    }
                                }
                            },
                            AlwaysEnabled: { type: "boolean" },
                            LeftValue: { type: "string" },
                            Func: { type: "string", defaultValue: "1" },
                            RightValue: { type: "string" },
                            DatasetName: {
                                type: "string",
                                validation: {
                                    required: { message: "Field is required"}
                                }
                            }
                        }
                    }
                }
            }),
        };

        $scope.groupUp = function (e) {
            for (var p = 0; p < $scope.customWorkflowsGrid.dataSource.data().length; p++) {
                $scope.customWorkflowsGrid.dataSource.data()[p].Position = p;
            }

            var dataItem = $scope.customWorkflowsGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.customWorkflowsGrid.dataSource.data();
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            if (index > 0) {
                $scope.customWorkflowsGrid.dataSource.remove(dataItem);
                $scope.customWorkflowsGrid.dataSource.insert(index - 1, dataItem);
            }
            $scope.customWorkflowsGrid.dataSource.sync();
        };

        $scope.groupDown = function (e) {
            for (var p = 0; p < $scope.customWorkflowsGrid.dataSource.data().length; p++) {
                $scope.customWorkflowsGrid.dataSource.data()[p].Position = p;
            }
            var dataItem = $scope.customWorkflowsGrid.dataItem($(e.currentTarget).closest("tr"));

            var index = null;
            var elements = $scope.customWorkflowsGrid.dataSource.data();

            for (var i = 0; i < elements.length; i++) {
                if (elements[i].Position == dataItem.Position) {
                    index = i;
                    break;
                }
            }
            $scope.customWorkflowsGrid.dataSource.remove(dataItem);
            $scope.customWorkflowsGrid.dataSource.insert(index + 1, dataItem);
            $scope.customWorkflowsGrid.dataSource.sync();
        };


        $scope.validateDataSource = function () {
            if (!$scope.model.DataSourceType) {
                return false;
            }
            if ($scope.model.DataSourceType === 'Workflow') {
                if (!$scope.portletConfigurationForm.outputVariable) {
                    return false;
                }
                return $scope.model.Workflows.length > 0; 
            }
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0] || !$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        };

        $scope.validateDbConnector = function(){
            if ($scope.model.DataSourceType === 'Database') {
                if (!$scope.model.Queries[0].ConnectorName) {
                    return false;
                }
            }
            return true;
        }

        $scope.getCrudMapperData = function () {
            return $scope.mapperOptions.dataSource.data();
        };

        $scope.ok = function () {
            
            $scope.mapperOptions.dataSource.sync();
            $scope.flowOptions.dataSource.sync();
            $scope.customWorkflowsGrid.dataSource.sync();

            $scope.configurationModel.WorkFlowModel.FlowConfigurations = [];

            var workflowConfigurations = $scope.customWorkflowsGrid.dataSource.data();
            for (var p = 0; p < workflowConfigurations.length; p++) {
                    $scope.configurationModel.WorkFlowModel.FlowConfigurations.push(workflowConfigurations[p]);
            }

            $scope.configurationModel.Mappers = [];

            var mapperOptions = $scope.mapperOptions.dataSource.data().toJSON();
            for (var i = 0; i < mapperOptions.length; i++) {
                if (mapperOptions[i].DatabaseColumn) {
                    mapperOptions[i].Id = i + 1;
                    $scope.configurationModel.Mappers.push(mapperOptions[i]);
                }
            }

            tableConfigurationService.saveProperties($scope.model.Properties, $scope.configurationModel, data.operation);

            $uibModalInstance.close($scope.model);
        };
        $scope.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };

        //table
        $scope.initialize = function () {
            $scope.createWorkflowTypes = [{
                    "type": "dsWithPagingAndFiltering",
                    "displayName": "Data Source with paging and filtering",
                }, {
                    "type": "datasource",
                    "displayName": "Data Source",
                }
            ];
        }();

    }
]);
