app.controller('RecordViewController',
	[
		'$scope', '$rootScope', 'recordViewConfigurationService', 'receiverService', 'flowService', 'editorService',
		'portletErrorService', 'bootstrapColumnClassService', 'translationsService', 'translationFlowClientService', 'globalVariableService',
		'appConfig', 'RecordViewService', '$controller', '$q', 'cascadeLovService',
		function ($scope,
			$rootScope,
			recordViewConfigurationService,
			receiverService,
			flowService,
			editorService,
			portletErrorService,
			bootstrapColumnClassService,
			translationsService,
			translationFlowClientService,
			globalVariableService,
			appConfig,
			RecordViewService,
			$controller,
            $q,
            cascadeLovService) {

			$controller('BasePluginController', { $scope: $scope });

			$scope.editorService = editorService;
			$scope.flowService = flowService;
			$scope.translationFlowClientService = translationFlowClientService;

			$scope.$watch('groups',
				function () {
				    cascadeLovService.setCurrentCascadeObject($scope.portlet.Id, $scope.flattenDetailView());
				},
				true);

			$scope.$watch('rvform_' + $scope.portlet.Id + '.$dirty',
				function (v) {
					if (!v) {
						return;
					};
					$scope['rvform_' + $scope.portlet.Id].$setPristine();

					if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {
						$scope.preventLostData.setForPortlet($scope.portlet.Id);
						console.log("change for portletId = ", $scope.portlet.Id);
					}

				});

			$scope.flattenDetailView = function () {

				var data = {};

				angular.forEach($scope.groups, function (group) {
					angular.forEach(group.Rows, function (row) {
						angular.forEach(row, function (column) {
							data[column.Name] = column.Value;
						});
					});
				});

				return data;

			};

			$scope.initPaginationModel = function () {

				$scope.PaginationModel = {};
				$scope.PaginationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
				$scope.PaginationModel.PageSize = 1;
				$scope.PaginationModel.PageNumber = 1;
				$scope.PaginationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
				$scope.PaginationModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
				$scope.PaginationModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;
				$scope.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;
				$scope.PaginationModel.Search = [];
				$scope.PaginationModel.Groups = [];

				angular.forEach($scope.configurationModel.GroupHeaders, function (x) {

					var mappers = _.filter($scope.configurationModel.Mappers, function (y) { return y.Group == x.GroupName; });
					var columns = _.flatMap(mappers, function (z) { return { Name: z.DatabaseColumn }; });

					$scope.PaginationModel.Groups.push({
						Title: x.GroupName,
						Columns: columns
					});
				});
			}

			$scope.createGroups = function (groups) {

				$scope.groups = [];

				angular.forEach(groups,
					function (group) {

						var groupName = group.Title;
						angular.forEach($scope.configurationModel.GroupHeaders,
							function (groupHeader) {
								if (groupHeader.GroupName === groupName) {
									groupName = translationsService.get(groupHeader.Code + '-GroupName', groupName);
								}
							});


						var gr = {
							GroupName: groupName,
							Rows: $scope.createRows(group)

						};
						if (gr.Rows.length > 0) {
							$scope.groups.push(gr);
						}
					});
			}

			$scope.createRows = function (group) {

				var rows = [];
				var columns = [];

				angular.forEach(group.Columns,
					function (column) {

						angular.forEach($scope.configurationModel.Mappers,
							function (mapper) {

								if (mapper.DatabaseColumn == column.Name && mapper.Group == group.Title) {

									var element = {
										Name: mapper.DatabaseColumn,
										DisplayName: translationsService.get(mapper.Code + '-DisplayName',
											mapper.DisplayName),
										IsMultiline: mapper.IsMultiline,
										Editor: mapper.Editor,
										Length: mapper.Length,
										Lov: mapper.Lov,
										IsEditable: mapper.IsEditable,
										Value: column.Value,
										IsHidden: mapper.IsHidden
									}

									columns.push(element);
									if (columns.length == $scope.configurationModel.NumberOfColumns) {
										rows.push(columns);
										columns = [];
									}
								}
							});
					});

				if (columns.length > 0) {
					rows.push(columns);
				}

				return rows;
			};


			$scope.setEditableColumns = function (value) {
				angular.forEach($scope.groups,
					function (group) {
						angular.forEach(group.Rows,
							function (row) {
								angular.forEach(row,
									function (column) {
										angular.forEach($scope.editDataCopy,
											function (editDataCopyGroup) {
												if (group.GroupName == editDataCopyGroup.GroupName) {
													angular.forEach(editDataCopyGroup.Rows,
														function (editDataCopyRow) {
															angular.forEach(editDataCopyRow,
																function (editDataCopyColumn) {
																	if (column.Name == editDataCopyColumn.Name) {
																		if (typeof value !== 'undefined') {
																			column.IsEditable = value;
																			column.Value = editDataCopyColumn.Value;
																		} else {
																			column.IsEditable =
																				editDataCopyColumn.IsEditable;
																		}

																	}
																});
														});
												}
											});
									});
							});
					});
			};

			$scope.createEditCopy = function () {
				$scope.editDataCopy = angular.copy($scope.groups);
			}

			$scope.setData = function (data) {
				$scope.createGroups(data.Groups);
				$scope.Total = data.Total;
			};

			$scope.getDisplayName = function (column) {

				if (column.Editor === 'LinkDisplay')
					return " ";

				if (!column.DisplayName)
					return " ";

				return column.DisplayName;

			}

			$scope.$on('onFilter',
				function (event, data) {

					$scope.PaginationModel.Search = [];
					if (receiverService.canReceive($scope.portlet, "onFilter", data)) {

						angular.forEach(data.Data, function (searchPredicate) {
							$scope.PaginationModel.Search.push(searchPredicate);
						});

						$scope.getData();
					}
				});

			$scope.$on('onRefresh',
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
						$scope.refresh();
					}
				});


			$scope.refresh = function (data) {

				if (data && data.Data) {
					$scope.PaginationModel.RowFilter = data.Data;
				}

				if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {
					var hasDataToLost = $scope.preventLostData.getForPortlet($scope.portlet.Id);

					var shouldManuallyRefresh = $q(function (resolve, reject) {
						if (hasDataToLost && $scope.controllingModel.InEdit) {
							resolve($scope.continue);
						} else {
							reject("Refresh not allowed");
						}
					});

					shouldManuallyRefresh.then(function (shouldContinue) {
						$scope.openModal();
					}).catch(function (error) {
						$scope.preventLostData.unsetForPortlet($scope.portlet.Id);
						$scope.getData();
					});
				} else {
					$scope.getData();
				}
			}

			$scope.$on('onRowSelected',
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
						$scope.refresh(data);
					}
				});

			$scope.$on('onGenericTreeNodeSelected',
				function (event, data) {
					if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
						$scope.PaginationModel.RowFilter = data.Data;
						$scope.getData();
					}
				});

			$scope.save = function () {

				var data = $scope.flattenDetailView();

				if ($scope.configurationModel.SaveWorkFlow && $scope.configurationModel.SaveWorkFlow.Id) {
					$scope.controllingModel.DisplayLoadingBar = true;
					var config = {
						workflowIdentifier: $scope.configurationModel.SaveWorkFlow.Id,
						dataObject: [data],
						callType: "Update",
						onSuccessAction: function () {
							$scope.controllingModel.InEdit = false;
							$scope.setEditableColumns(false);
							$scope.controllingModel.DisplayLoadingBar = false;
							$scope.refresh();

							if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {
								$scope.preventLostData.unsetForPortlet($scope.portlet.Id);
								console.log("change for portletId = ", $scope.portlet.Id);
							}

						},
						onErrorAction: function () {
							$scope.controllingModel.DisplayLoadingBar = false;
						},
						filterDataObject: $scope.PaginationModel.RowFilter,
						dataDefinitions: $scope.configurationModel.Mappers,
						portletInternalName: $scope.portlet.InternalName
					};
					$scope.flowService.callFlowWithTableData(
						$scope.configurationModel.SaveWorkFlow.RootElementName,
						config,
                        $scope.portlet.Id);
				}

			};

			$scope.edit = function () {
				$scope.setEditableColumns();
				$scope.controllingModel.InEdit = true;
			};

			$scope.cancel = function () {
				$scope.setEditableColumns(false);
				$scope.controllingModel.InEdit = false;

				if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {
					$scope.preventLostData.unsetForPortlet($scope.portlet.Id);
					console.log("change for portletId = ", $scope.portlet.Id);
				}

			};

			$scope.continue = false;

			$scope.openModal = function () {
				jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('show');
			};

			$scope.refuseRefresh = function () {
				$scope.continue = false;
				jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('hide');
			};

			$scope.openUnsavedChangesModal = function () {
				jQuery("#unsavedChangesModal").modal('show');
			}

			$scope.$on("preventDataLost",
				function (event, data) {
					$scope.shouldWarnAboutDataToLose().then(function (shouldContinue) {
						$scope.openUnsavedChangesModal();
					}).catch(function () { });
				});


			$scope.acceptRefresh = function (data) {

				$scope.continue = true;
				jQuery("#refreshConfirmationModal_" + $scope.portlet.Id).modal('hide');
				$scope.preventLostData.unsetForPortlet($scope.portlet.Id);
				$scope.getData();

			};

			$scope.getPagerOptions = function () {


				return {
					buttonCount: 3,
					refresh: true,
					dataSource: new kendo.data.DataSource({
						pageSize: $scope.PaginationModel.PageSize,
						batch: true,
						serverPaging: true,
						transport: {
							read: function (options) {

								$scope.controllingModel.DisplayLoadingBar = true;


								$scope.PaginationModel.StartIndex = options.data.skip;
								$scope.PaginationModel.PageNumber = options.data.page;

								var pagerDataSource = {};
								var data = [
								];
								pagerDataSource.data = data;


								RecordViewService.getRecordViewData($scope.PaginationModel, $scope.portlet.Id)
									.then(
										function (result) {
											pagerDataSource.Total = result.data.Total;
											options.success(pagerDataSource);

											$scope.setData(result.data);

											$scope.createEditCopy();
											$scope.setEditableColumns(false);
											$scope.controllingModel.InEdit = false;
										}).catch(angular.noop).finally(function () {
											$scope.controllingModel.DisplayLoadingBar = false;
										});
							}
						}
						,
						schema: {
							total: "Total",
							data: "data"
						}
					})
				};
			};


			$scope.getData = function () {
				$scope.pagerOptions = $scope.getPagerOptions();
				$scope.pagerOptions.dataSource.read();
			};

			$scope.runCustomFlow = function (flowId, userActions, datasetName) {

				var hasDataToLost = $scope.preventLostData.getForPortlet($scope.portlet.Id);

				if (hasDataToLost) {
					$scope.openUnsavedChangesModal();
				} else {

					var data = $scope.flattenDetailView();

					if (userActions) {
						$scope.flowService.runFlowUserAction($scope,
							flowId,
							[data],
							$scope.configurationModel.Mappers,
							appConfig.WorkflowOperationType.CUSTOM_WORKFLOW,
							$scope.PaginationModel.RowFilter,
							$scope.refresh,
							datasetName,
							$scope.portlet.InternalName
						);
					} else {
						$scope.controllingModel.DisplayLoadingBar = true;


						var objToSend = {
							workflowIdentifier: flowId,
							dataObject: [data],
							callType: appConfig.WorkflowOperationType.CUSTOM_WORKFLOW,
							onSuccessAction: function () {
								$scope.controllingModel.DisplayLoadingBar = false;
								$scope.controllingModel.InEdit = false;
								$scope.refresh();
							},
							onErrorAction: function () {
								$scope.controllingModel.DisplayLoadingBar = false;
							},
							filterDataObject: $scope.PaginationModel.RowFilter,
							dataDefinitions: $scope.configurationModel.Mappers,
							portletInternalName: $scope.portlet.InternalName
						};
						$scope.flowService.callFlowWithTableData(datasetName, objToSend, $scope.portlet.Id);
					}
				}
			};

			$scope.initialize = function () {

				$scope.configurationModel = recordViewConfigurationService.getConfigurationModel($scope.portlet.Properties);
				$scope.controllingModel = recordViewConfigurationService.getControllingModel();
				$scope.classSize = bootstrapColumnClassService.getClassSize($scope.configurationModel.NumberOfColumns);
				$scope.initPaginationModel();
				$scope.getData();

			};

			$scope.initialize();
		}
	]);
