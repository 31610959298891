app.controller("TableController",
    ["$scope", "$rootScope", "$uibModal", "tableConfigurationService",
        "receiverService", "editorService", "portletErrorService", "localStorageService", "translationsService", "translationFlowClientService",
        "dataEvalService", "globalVariableService", "$compile", "sharedSessionService", "appConfig", "$controller",
        "$timeout", 'TableService', "$q",
        function ($scope,
            $rootScope,
            $uibModal,
            tableConfigurationService,
            receiverService,
            editorService,
            portletErrorService,
            localStorageService,
            translationsService,
            translationFlowClientService,
            dataEvalService,
            globalVariableService,
            $compile,
            sharedSessionService,
            appConfig,
            $controller,
            $timeout,
            TableService, $q
        ) {

            $scope.timer = null;
            $scope.total = 0;

            $controller('BaseTableController', { $scope: $scope });
            $controller('TableWorkflowExecutionController', { $scope: $scope });

            $scope.translationFlowClientService = translationFlowClientService;

            $scope.selectionModel = {};
            $scope.selectionModel.selectedRows = [];
            $scope.selectionModel.emptySelection = true;
            $scope.width = 0;
            $scope.shouldApplyWidth = false;
            $scope.resetPager = false;

            function canCreate() {
                var workflowObj = $scope.configurationModel.WorkFlowModel.CrudWorkflow;
                return ($scope.hasWorkflow(workflowObj) && workflowObj.CanCreate) ? true : false;
            }

            function canUpdate() {
                var workflowObj = $scope.configurationModel.WorkFlowModel.CrudWorkflow;
                return ($scope.hasWorkflow(workflowObj) && workflowObj.CanUpdate) ? true : false;
            }

            $scope.gridIsEditableForNewRowsOnly = function () {
                return $scope.configurationModel.EditableForNewRowsOnly;
            };

            $scope.newRowFilter = function (rowFilter) {
                if ($scope.gridOptions) {
                    $scope.resetPager = true;
                    $scope.PaginationModel.RowFilter = rowFilter;
                    $scope.refreshSilently();
                } else {
                    $scope.initialize(rowFilter);
                }
            };

            $scope.$on(
                "$destroy",
                function () {
                    $timeout.cancel($scope.timer);
                }
            );

            $scope.$on("onRefresh",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.PaginationModel.RefreshWorkflowCache = true;
                        $scope.refresh();
                    }
                });

            $scope.$on("onRowSelected",
                function (event, data) {

                    if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                        $scope.selectionModel.selectedRows = [];
                        $scope.PaginationModel.RefreshWorkflowCache = true;

                        if ($scope.timer === null) {
                            $scope.timer = $timeout(function () {
                                $scope.newRowFilter(data.Data);
                                $scope.refresh();
                                $timeout.cancel($scope.timer);
                                $scope.timer = null;
                            }, 200);
                        }
                    }
                });

            $scope.$on("onFilter",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                        $scope.selectionModel.selectedRows = [];
                        $scope.PaginationModel.ExternalSearch = [];
                        var filter = { logic: "and", filters: [] };

                        $timeout(function () {

                            angular.forEach(data.Data, function (searchPredicate) {
                                filter.filters.push({
                                    field: searchPredicate.Key,
                                    operator: searchPredicate.Operator,
                                    value: searchPredicate.Value
                                });

                            });
                        }, 100);

                        if ($scope.timer !== null) {
                            $timeout.cancel($scope.timer);
                            $scope.timer = null;
                        }

                        $scope.timer = $timeout(function () {
                            $scope.gridOptions.dataSource.filter(filter);

                            $timeout.cancel($scope.timer);
                            $scope.timer = null;
                        }, 500);
                    }
        });

            $scope.$on("onGenericTreeNodeSelected",
                function (event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                        $scope.selectionModel.selectedRows = [];
                        $scope.PaginationModel.RefreshWorkflowCache = true;
                        $scope.newRowFilter(data.Data);
                    }
                });

            $scope.rowDblClickFromButton = function (e) {

                var dataItem = $scope.tableGrid.dataItem($(e.currentTarget).closest("tr"));
                $scope.callDblClick(dataItem);
            };

            $scope.createToolbar = function () {
                var toolbar = [];

                if($scope.gridIsEditable()) {
                    
                    if (canUpdate()) {
                        toolbar.push({ name: "save" });
                    }

                    if (canCreate()) {
                        toolbar.push({ name: "create" });
                    }

                    if(canCreate() || canUpdate()) {
                        toolbar.push({ name: "cancel" });
                    }
    
                }
                
                angular.forEach($scope.configurationModel.WorkFlowModel.FlowConfigurations,
                    function (flowConfig) {

                        if (flowConfig.CustomFlow) {

                            var datasetName = null;

                            if (flowConfig.DatasetName)
                                datasetName = flowConfig.DatasetName;

                            this.push({
                                Id: flowConfig.CustomFlow.Id,
                                text: flowConfig.DisplayName,
                                template: '<button ng-click="runCustomFlow(\'' +
                                    flowConfig.CustomFlow.Id +
                                    "', " +
                                    flowConfig.UserActions +
                                    ",'" +
                                    datasetName + "'" +
                                    ')" class="k-button k-button-icontext k-grid-export" ng-disabled="customFlowDisabled(' +
                                    flowConfig.Id +
                                    ')">' +
                                    translationsService.get(flowConfig.Code + "-DisplayName", flowConfig.DisplayName) +
                                    "</button>"
                            });
                        }
                    },
                    toolbar);

                if ($scope.configurationModel.Exportable) {
                    toolbar.push({
                        name: "export",
                        template: '<button ng-click="export()" class="k-button k-button-icontext k-grid-export">' +
                            translationFlowClientService.get("Export") +
                            "</button>"
                    });
                }


                return toolbar;
            };

            $scope.saveColumns = function () {
                var columnsToSave = [];

                $.each($scope.tableGrid.getOptions().columns,
                    function (index, col) {
                        var colSlim = {};
                        colSlim.field = col.field;
                        colSlim.width = col.width;
                        colSlim.hidden = col.hidden;
                        colSlim.ColumnCode = col.ColumnCode;

                        columnsToSave.push(colSlim);
                    });

                localStorageService.save($scope.portlet.Id,
                    sharedSessionService.getUser(),
                    "columns",
                    columnsToSave);
            };

            $scope.onColumnResize = function () {
                $scope.saveColumns();
            };

            $scope.onColumnShow = function () {
                $scope.saveColumns();
            };

            $scope.onColumnHide = function () {
                $scope.saveColumns();
            };

            $scope.onColumnReorder = function () {
                setTimeout(function () {
                    $scope.saveColumns();
                }, 5);
            };

            $scope.onEdit = function (e) {
                if ($scope.gridIsEditable() && $scope.gridIsEditableForNewRowsOnly() && !e.model.isNew()) {
                    $scope.tableGrid.closeCell();
                }
            };

            $scope.removeFiltersForField = function (expression, field) {
                if (expression.filters) {
                    expression.filters = $.grep(expression.filters,
                        function (filter) {
                            $scope.removeFiltersForField(filter, field);
                            if (filter.filters) {
                                return filter.filters.length;
                            } else {
                                return filter.field !== field;
                            }
                        });
                }
            };

            $scope.export = function () {
                var modalInstance = $uibModal.open({
                    templateUrl: $.pluginsDir + "/Table/Export.html",
                    controller: "ExportController",
                    backdrop: "static",
                    keyboard: false
                });

                modalInstance.result.then(function (modalScope) {
                    var paginationModel = angular.copy($scope.PaginationModel);

                    // Get the name/names of the dataSource that is used to create the data
                    var DataSourceNames = $scope.portlet.Workflows;
                    var FileName = '';
                    for (var item = 0; item < DataSourceNames.length; ++item) {
                        FileName += DataSourceNames[item].Name.replace(/\s/g, '');
                    }
                    if (!FileName.length) {
                        FileName += 'export';
                    }
                    FileName += ".xlsx";

                    if (modalScope === "Full") {
                        paginationModel.StartIndex = 0;
                        paginationModel.PageNumber = 1;
                        paginationModel.PageSize = $scope.total;
                    }
                    TableService.exportTable(paginationModel, $scope.portlet.Id)
                        .then(function (result) {
                            var blob = new Blob([result.data],
                                { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                            saveAs(blob, FileName);
                        }).catch(angular.noop);
                }, function () {
                    //handle popup dismiss on cancel
                }).catch(function () { });
            };

            $scope.getData = function () {

                TableService.getTableHeader($scope.PaginationModel, $scope.portlet.Id)
                    .then(function (result) {

                        $scope.controllingModel.DisplayLoadingBar = false;

                        $scope.headerResult = result;

                        if ($scope.headerResult.data.Headers.length > 0) {
                            $scope.gridOptions = $scope.getTableOptions($scope.headerResult);
                        }
                    }).catch(angular.noop).finally(
                        function () {
                            $scope.controllingModel.DisplayLoadingBar = false;
                        }
                    );
            };

            $scope.read = function (options) {

                $scope.resetPager ? $scope.PaginationModel.StartIndex = 0 : $scope.PaginationModel.StartIndex = options.data.skip;

                if (options) {
                    $scope.PaginationModel.PageNumber = options.data.page;
                }

                $scope.setPaginationSort($scope.PaginationModel, options, $scope.configurationModel);

                $scope.setPaginationSearch($scope.PaginationModel, options, $scope.configurationModel, $scope.headerResult.data.Headers);

                if (options) {
                    if (options.data.group && options.data.group.length === 2) {
                        options.success([]);
                    } else {
                        $scope.setPaginationGroup($scope.PaginationModel, options);


                        TableService.getTableData($scope.PaginationModel, $scope.portlet.Id)
                            .then(function (result) {
                                $scope.total = result.data.Total;
                                $scope.mapBooleanDataTo(result.data);
                                options.success(result.data);
                            }).catch(function (error) {
                                options.error(error);
                            })
                            .finally(function () {
                                $scope.controllingModel.DisplayLoadingBar = false;
                                $scope.PaginationModel.RefreshWorkflowCache = false;
                                $scope.resetPager = false;
                            });
                    }
                }
            };

            $scope.getTableOptions = function (result) {

                var toolBarContent = $scope.createToolbar();

                var tableOptions = {
                    toolbar: toolBarContent.length ? toolBarContent : undefined,
                    editable: $scope.gridIsEditable() && canUpdate() ? { mode: "incell", confirmation: false } : false,
                    groupable: $scope.configurationModel.Groupable,
                    navigatable: true,
                    pageable: {
                        refresh: true
                    },
                    edit: $scope.onEdit,
                    columnMenu: $scope.configurationModel.ColumnMenu,
                    dataBinding: $scope.onDataBinding,
                    dataBound: function (e) {
                        /**
                        * Grid cancel edit cell
                        */
                        jQuery(e.sender.element).on("mousedown", ".k-grid-cancel-changes", function (e) {
                            $scope.preventLostData.unsetForPortlet($scope.portlet.Id);
                        });
                        $scope.onDataBound(e);
                    },
                    columnResize: $scope.onColumnResize,
                    columnReorder: $scope.onColumnReorder,
                    change: $scope.onChange,
                    selectable: $scope.configurationModel.MultiSelect ? "multiple" : true,
                    resizable: $scope.configurationModel.Resizable,
                    reorderable: $scope.configurationModel.Reorderable,
                    columnShow: $scope.onColumnShow,
                    columnHide: $scope.onColumnHide,
                    sortable: $scope.configurationModel.Sortable ? {
                        mode: "multiple",
                        showIndexes: true
                    }:false,
                    dataSource: new kendo.data.DataSource({
                        pageSize: $scope.PaginationModel.PageSize,
                        batch: true,

                        transport: {
                            read: function (options) {

                                if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {

                                    $scope.refreshOkFn = 'read';

                                    /**
                                     * Show modal, or just refresh
                                     */
                                    $scope.shouldManuallyRefresh().then(function (shouldContinue) {
                                        $scope.openModal(options);
                                    }).catch(function (error) {
                                        $scope[$scope.refreshOkFn](options);
                                    });
                                }
                                else {
                                    $scope.read(options);
                                }
                            },
                            create: function (options) {
                                if (canCreate()) {

                                    $scope.preventLostData.unsetForPortlet($scope.portlet.Id);

                                    $scope.callCrudFlow(options,
                                        $scope.configurationModel.WorkFlowModel.CrudWorkflow.Id,
                                        "Add");
                                }
                            },
                            update: function (options) {
                                if (canUpdate()) {

                                    $scope.preventLostData.unsetForPortlet($scope.portlet.Id);

                                    $scope.callCrudFlow(options,
                                        $scope.configurationModel.WorkFlowModel.CrudWorkflow.Id,
                                        "Update");
                                }
                            },
                            destroy: function (options) {
                                if ($scope.canDelete()) {
                                    $scope.callCrudFlow(options,
                                        $scope.configurationModel.WorkFlowModel.CrudWorkflow.Id,
                                        "Delete");
                                }
                            }
                        },
                        change: function (e) {
                            if (e.field) {
                                if ($scope.featuresFlags.hasFeatureFlag("PortalPreventLostData")) {
                                    $scope.preventLostData.setForPortlet($scope.portlet.Id);
                                }
                            }
                        },
                        schema: {
                            data: "Rows",
                            total: "Total",
                            groups: "Groups",
                            model: {
                                id: "rnum",
                                fields: $scope.createFields(result.data.Headers)
                            }
                        },
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        serverGrouping: true
                    }),
                    columns: $scope.createColumns(result.data.Headers)

                    
                };

                /* Grouping */
                if ($scope.configurationModel.Groupable) {
                    var localStorageGroup = localStorageService
                        .get($scope.portlet.Id, sharedSessionService.getUser(), "group");

                    var localStorageGroupExists = false;

                    if (tableOptions && tableOptions.columns && localStorageGroup && localStorageGroup.field) {
                        for (var x in tableOptions.columns) {
                            if (Object.prototype.hasOwnProperty.call(tableOptions.columns, x)) {
                                if (tableOptions.columns[x].field === localStorageGroup.field) {
                                    localStorageGroupExists = true;
                                }
                            }
                        }
                    }

                    var localStorageGroupExistsAsColumn = tableOptions &&
                        tableOptions.columns &&
                        localStorageGroup &&
                        localStorageGroup.field &&
                        localStorageGroupExists;

                    if (localStorageGroup && localStorageGroupExistsAsColumn) {
                        tableOptions.dataSource.group([
                            localStorageGroup
                        ]);
                    } else if ($scope.PaginationModel.GroupBy) {
                        tableOptions.dataSource.group([
                            {
                                aggregates: [],
                                dir: "asc",
                                field: $scope.PaginationModel.GroupBy
                            }
                        ]);
                    }
                }

                /* Sorting */
                var localStorageSort = localStorageService
                    .get($scope.portlet.Id, sharedSessionService.getUser(), "sort");
                if (localStorageSort) {
                    tableOptions.dataSource.sort(localStorageSort);
                }

                /* Filter */
                if ($scope.configurationModel.Filterable) {
                    tableOptions.filterable = {
                        extra: false
                    };
                }
                var localStorageFilter = localStorageService
                    .get($scope.portlet.Id, sharedSessionService.getUser(), "filter");
                if (localStorageFilter) {
                    tableOptions.dataSource.filter(localStorageFilter);
                }

                return tableOptions;
            };

            $scope.initialize = function (rowFilter) {

                $scope.configurationModel = tableConfigurationService.getConfigurationModel($scope.portlet.Properties);
                $scope.controllingModel = tableConfigurationService.getControllingModel();

                $scope.enumerableFlowConfigs = $scope.configurationModel.WorkFlowModel.FlowConfigurations;
                $scope.orderedColorRulesBackground = _.sortBy($scope.configurationModel.colorRulesBackground, function (rule) { return rule.Position; });

                $scope.PaginationModel = {};
                $scope.PaginationModel.CurrentUICulture = kendo.culture().name;
                $scope.PaginationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
                $scope.PaginationModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
                $scope.PaginationModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;
                $scope.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;
                $scope.PaginationModel.PortletId = $scope.portlet.Id;
                $scope.PaginationModel.GroupBy = $scope.configurationModel.GroupingModel.GroupBy;
                $scope.PaginationModel.Search = [];
                $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
                $scope.PaginationModel.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;
                $scope.PaginationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
                $scope.PaginationModel.RefreshWorkflowCache = false;
                $scope.PaginationModel.UseIndividualCache = $scope.configurationModel.UseIndividualCache;

                if (rowFilter) {
                    $scope.PaginationModel.RowFilter = rowFilter;
                }

                $scope.getData();
            };

            $scope.initialize();

        }
    ]);
