app.factory('tableConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (properties) {
        var configurationModel = configurationService.getConfigurationModel(properties);
        configurationModel.colorRulesBackground = $.parseJSON(propertyService.getPropertyValue(properties, "colorRulesBackground", "[]"));
        /* Flows START */
        configurationModel.WorkFlowModel = {};
        configurationModel.WorkFlowModel.DblClickUserActions = propertyService.getBoolPropertyValue(properties, "dblClickUserActions");
        configurationModel.WorkFlowModel.CrudWorkflow = $.parseJSON(propertyService.getPropertyValue(properties, "crudWorkflow", "{}"));
        configurationModel.WorkFlowModel.DblClickWorkflow = $.parseJSON(propertyService.getPropertyValue(properties, "dblClickWorkflow", "{}"));;
        configurationModel.WorkFlowModel.FlowConfigurations = $.parseJSON(propertyService.getPropertyValue(properties, "flowConfigurations", "[]"));

        //include or exclude dataSource items( cacheable) 
        configurationModel.datasourceWorkflowPath = "dataSourceWorkflows/true";

        /* Flows END */

        configurationModel.Mappers = $.parseJSON(propertyService.getPropertyValue(properties, "mappers", "[]"));
        configurationModel.PageSize = parseInt(propertyService.getPropertyValue(properties, "pageSize", "10"));
        configurationModel.ReadOnly = propertyService.getBoolPropertyValue(properties, "readOnly",true);
        configurationModel.EditableForNewRowsOnly = propertyService.getBoolPropertyValue(properties, "editableForNewRowsOnly",false);
        configurationModel.Groupable = propertyService.getBoolPropertyValue(properties, "groupable");    
        configurationModel.Resizable = propertyService.getBoolPropertyValueAllowEmpty(properties, "resizable", true);
        configurationModel.Reorderable = propertyService.getBoolPropertyValueAllowEmpty(properties, "reorderable", true);
        configurationModel.MultiSelect = propertyService.getBoolPropertyValue(properties, "multiSelect");
        configurationModel.Exportable = propertyService.getBoolPropertyValue(properties, "exportable");
        configurationModel.Sortable = propertyService.getBoolPropertyValueAllowEmpty(properties, "sortable", true);
        configurationModel.Filterable = propertyService.getBoolPropertyValueAllowEmpty(properties, "filterable", true);
        configurationModel.ColumnMenu = propertyService.getBoolPropertyValueAllowEmpty(properties, "columnMenu", true);
        configurationModel.IgnoreExecutionCheck = propertyService.getBoolPropertyValue(properties, "ignoreExecutionCheck");
        configurationModel.UseIndividualCache = propertyService.getBoolPropertyValue(properties, "useIndividualCache");
        
        var colWidth = propertyService.getPropertyValueAllowEmpty(properties, "DefaultColumnWidth", "150");

        if (colWidth) {
            configurationModel.DefaultColumnWidth = parseInt(colWidth);
        }

        configurationModel.GroupingModel = {};
        configurationModel.GroupingModel.GroupBy = propertyService.getPropertyValue(properties, "groupBy", "");

        configurationModel.DefaultSortingModel = {};
        configurationModel.DefaultSortingModel.OrderBy = propertyService.getPropertyValue(properties, "DefaultOrderBy", undefined);
        configurationModel.DefaultSortingModel.Direction = propertyService.getPropertyValue(properties, "DefaultOrderByDirection", 'asc');

        configurationService.assignIds(configurationModel.Mappers);
        configurationService.assignIds(configurationModel.WorkFlowModel.FlowConfigurations);

        return configurationModel;
    }

    service.getControllingModel = function () {
        return configurationService.getControllingModel();        
    }

    service.saveProperties = function (properties, configurationModel, operation) {

        configurationService.saveProperties(properties, configurationModel);

        if (configurationModel.Mappers && operation == "copy") {
            configurationService.assignCodes(configurationModel.Mappers);
        }

        if (configurationModel.Mappers) {
            propertyService.setProperty(properties, "mappers", JSON.stringify(configurationModel.Mappers));
        }

        propertyService.setProperty(properties, "pageSize", configurationModel.PageSize.toString());

        colWidth = configurationModel.DefaultColumnWidth;
        if (colWidth >= 0 ) {
            propertyService.setProperty(properties, "DefaultColumnWidth", configurationModel.DefaultColumnWidth.toString());
        } else {
            propertyService.setProperty(properties, "DefaultColumnWidth", "65");
        }
        
        /* Flows START */
   
        if (configurationModel.WorkFlowModel.CrudWorkflow) {
            propertyService.setPropertyWithRemoveOnEmpty(properties,"crudWorkflow",
                        JSON.stringify(configurationModel.WorkFlowModel.CrudWorkflow));
        }
        if (configurationModel.WorkFlowModel.DblClickWorkflow) {
            propertyService.setPropertyWithRemoveOnEmpty(properties, "dblClickWorkflow",
                        JSON.stringify(configurationModel.WorkFlowModel.DblClickWorkflow));
        }
        if (configurationModel.WorkFlowModel.FlowConfigurations && operation == "copy") {
            configurationService.assignCodes(configurationModel.WorkFlowModel.FlowConfigurations);
        }

        if (configurationModel.WorkFlowModel.FlowConfigurations) {
            propertyService.setProperty(properties, "flowConfigurations", JSON.stringify(configurationModel.WorkFlowModel.FlowConfigurations));
        }

        /* Flows END*/
        propertyService.setBoolProperty(properties, "readOnly", configurationModel.ReadOnly);
        propertyService.setBoolProperty(properties, "editableForNewRowsOnly", configurationModel.EditableForNewRowsOnly);
        propertyService.setBoolProperty(properties, "groupable", configurationModel.Groupable);
        propertyService.setBoolProperty(properties, "resizable", configurationModel.Resizable);
        propertyService.setBoolProperty(properties, "reorderable", configurationModel.Reorderable);
        propertyService.setBoolProperty(properties, "multiSelect", configurationModel.MultiSelect);
        propertyService.setBoolProperty(properties, "exportable", configurationModel.Exportable);
        propertyService.setBoolProperty(properties, "sortable", configurationModel.Sortable);
        propertyService.setBoolProperty(properties, "filterable", configurationModel.Filterable);
        propertyService.setBoolProperty(properties, "columnMenu", configurationModel.ColumnMenu);
        propertyService.setBoolProperty(properties, "ignoreExecutionCheck", configurationModel.IgnoreExecutionCheck);
        propertyService.setBoolProperty(properties, "useIndividualCache", configurationModel.UseIndividualCache);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "groupBy", configurationModel.GroupingModel.GroupBy);
        propertyService.setBoolProperty(properties, "dblClickUserActions", configurationModel.WorkFlowModel.DblClickUserActions);
        
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderBy", configurationModel.DefaultSortingModel.OrderBy);
        propertyService.setPropertyWithRemoveOnEmpty(properties, "DefaultOrderByDirection", configurationModel.DefaultSortingModel.Direction);

        if (configurationModel.colorRulesBackground) {
            propertyService.setProperty(properties, "colorRulesBackground", JSON.stringify(configurationModel.colorRulesBackground));
        }
    }

    return service;
}]);