app.service('GenericDocumentViewerUrlService', ['basePluginService', '$q',
    function (basePluginService, $q) {
        angular.extend(this, basePluginService);

        var service = {};

        service.getDocument = function (DocumentViewerViewModel, headers, portletId) {     
            return basePluginService.backend.postWithHeaders("genericDocumentViewerUrl", DocumentViewerViewModel, headers, portletId, true);
        };

        return service;
    }]);