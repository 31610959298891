app.factory('genericDocumentViewerUrlConfigurationService', ['propertyService', 'configurationService', function (propertyService, configurationService) {
    var service = {};

    service.getConfigurationModel = function (model) {

        var configurationModel = configurationService.getConfigurationModel(model.Properties);

        configurationModel.FileName = propertyService.getPropertyValue(model.Properties, "fileName", "");
        configurationModel.MimeType = propertyService.getPropertyValue(model.Properties, "mimeType", "");
        configurationModel.Content = model.Content;
        return configurationModel;
    };

    service.getControllingModel = function () {
        return configurationService.getControllingModel();
    };

    service.saveProperties = function (properties, configurationModel) {
        configurationService.saveProperties(properties, configurationModel);
        propertyService.setProperty(properties, "fileName", configurationModel.FileName);
        propertyService.setProperty(properties, "mimeType", configurationModel.MimeType);
    };

    return service;
}]);