app.factory('basePluginService', ['$rootScope', '$http', '$q', 'appConfig', function ($rootScope,$http, $q, appConfig) {

    var service = {};

    var backend = {

        get: function (action, portletId) {
            var portletIdValue = portletId || undefined;

            var deferred = $q.defer();

            $http({
                method: "GET",
                url: appConfig.API_PLUGIN_URL + action,
                headers: {
                    'PortletId': portletIdValue
                }
            }).then(function (resData, status, headers, config) {       
                $rootScope.$broadcast("hideErrors", { PortletId: portletIdValue });
                deferred.resolve(resData);
            }).catch(function (resData, status, headers, config) {
                deferred.reject(resData);
            });
            return deferred.promise;
        },
        post: function (action, data, portletId, dontHideErrors) {
            return this.postWithHeaders(action, data, undefined, portletId, undefined, dontHideErrors);
        },

        postWithHeaders: function (action, data, headers, portletId, isArrayBuffer, dontHideErrors) {
            var deferred = $q.defer();

            if (portletId && headers)
                headers.PortletId = portletId;
            else if (portletId) {
                headers = {
                    PortletId: portletId
                };
            }
            if (isArrayBuffer) {
                $http({
                    method: "POST",
                    url: appConfig.API_PLUGIN_URL + action,
                    data: data,
                    responseType: "arraybuffer",
                    headers: headers
                })
                    .then(function (resData, status, headers, config) {
                        if (!dontHideErrors) {
                            $rootScope.$broadcast("hideErrors", { PortletId: portletId });
                        }
                        deferred.resolve(resData);
                    })
                    .catch(function (resData, status, headers, config) {
                        deferred.reject(resData);
                    });
            } else
            {
                $http({
                    method: "POST",
                    url: appConfig.API_PLUGIN_URL + action,
                    data: data,
                    headers: headers
                })
                    .then(function (resData, status, headers, config) {
                        if (!dontHideErrors) {
                            $rootScope.$broadcast("hideErrors", { PortletId: portletId });
                        }
                        deferred.resolve(resData);
                    })
                    .catch(function (resData, status, headers, config) {
                        deferred.reject(resData);
                    });
            }

            return deferred.promise;
        },

        put: function (action, data, headers, portletId) {

            var deferred = $q.defer();

            $http({
                method: "PUT",
                url: appConfig.API_PLUGIN_URL + action,
                data: data,
                headers: headers
            })
                .then(function (resData, status, headers, config) {
                    $rootScope.$broadcast("hideErrors", { PortletId: portletId });
                    deferred.resolve(resData);
                })
                .catch(function (resData, status, headers, config) {
                    deferred.reject(resData);
                });

            return deferred.promise;
        },
        'delete': function (action, portletId) {

            var deferred = $q.defer();

            if (portletId) {
                headers = {
                    PortletId: portletId
                };
            }
            $http({
                method: "DELETE",
                url: appConfig.API_PLUGIN_URL + action,
                headers: headers
            })
                .then(function (resData, status, headers, config) {
                    $rootScope.$broadcast("hideErrors", { PortletId: portletId });
                    deferred.resolve(resData);
                })
                .catch(function (resData, status, headers, config) {
                    deferred.reject(resData);
                });

            return deferred.promise;
        },
    };
    service.backend = backend;

    return service;

}]);

