app.controller("Chart2Controller",
[
    "$scope", "$rootScope", "ChartService", "$uibModal", "chart2ConfigurationService","receiverService",
    "editorService", "portletErrorService", "localStorageService", "translationsService",
    "dataEvalService", "globalVariableService", "$compile", "sharedSessionService", "appConfig", "$timeout","$controller",
    function($scope,
        $rootScope,
        ChartService,
        $uibModal,
        chart2ConfigurationService,    
        receiverService,
        editorService,
        portletErrorService,
        localStorageService,
        translationsService,
        dataEvalService,
        globalVariableService,
        $compile,
        sharedSessionService,
        appConfig, $timeout, $controller) {
        
        $controller('BasePluginController', { $scope: $scope });

        $scope.newRowFilter = function(rowFilter) {
            if ($scope.chartData) {
                $scope.PaginationModel.RowFilter = rowFilter;
                $scope.refreshSilently();
            }
        };

        $scope.$on("onMaximized",
            function (event, data) {
                $scope.refreshSilently();
            });

        $scope.$on("onMinimized",
            function (event, data) {
                $scope.refreshSilently();
            });

        $scope.$on("onRowSelected",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                    $scope.newRowFilter(data.Data);
                }
            });

        $scope.$on("onRefresh",
            function (event, data) {
                if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                    $scope.refresh();
                }
            });


        $scope.$on("portletTabChanged",
            function(event, data) {
                if (data && data.portlet && $scope.portlet.Id === data.portlet) {
                    $timeout(function() {
                        if ($scope.chart) {
                            $scope.chart.redraw();
                        }
                    },
                        50);
                }
            });

        $scope.$on("resize",
            function(event, data) {
                if (data) {
                    for (var i = 0; i < data.PortletIds.length; i++) {
                        if (data.PortletIds[i] === $scope.portlet.Id) {
                            if ($scope.chart) {
                                $scope.chart.redraw();
                            }
                        }
                    }
                } else {
                    if ($scope.chart) {
                        $scope.chart.redraw();
                    }
                }
            });

        $scope.$on("onFilter",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onFilter", data)) {
                    $scope.PaginationModel.ExternalSearch = [];
                    var filter = { logic: "and", filters: [] };

                    angular.forEach(data.Data, function (searchPredicate) {
                        filter.filters.push({
                            field: searchPredicate.Key,
                            operator: searchPredicate.Operator,
                            value: searchPredicate.Value
                        });
                    });

                    $scope.chartData.filter(filter);
                }
            });

        $scope.refreshSilently = function() {
            $scope.chartData.read();
        };

        $scope.refresh = function() {
            $scope.refreshSilently();
            $rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });

        };

        $scope.createFields = function(columns) {
            var fields = {};

            angular.forEach(columns, function (x) {
                fields[x.ColumnCode] = {
                    editable: true,
                    type: "string"
                };
            });

            return fields;
        };

        $scope.onSeriesClick = function(e) {
            if (e.category) {
                e.dataItem[e.series.categoryAxis] = e.category;
            }

            $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: e.dataItem });
        };

        $scope.applyBaseChartOptions = function() {

            if (!$scope.configurationModel.ChartModel.ChartOptions.axisDefaults) {
                $scope.configurationModel.ChartModel.ChartOptions.axisDefaults = {};
            }

            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.crosshair = { color: "#8e8e8e" };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.labels = {
                color: "#232323",
                font: "12px Arial, Helvetica, sans-serif",
                rotation: "auto",
                template:  $scope.getFormatingTemplate($scope.configurationModel.ChartModel.LabelFormating)

            };
            
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.line = { color: "#8e8e8e" };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.majorGridLines = { color: "#dfdfdf" };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.minorGridLines = { color: "#f0f0f0" };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.notes = {
                icon: {
                    background: "transparent",
                    border: { color: "#8e8e8e", width: 1 }
                },
                label: { color: "#232323", font: "12px Arial, Helvetica, sans-serif", padding: 3 },
                line: { color: "#8e8e8e", length: 10, width: 2 },
                visible: true
            };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.title = {
                color: "#232323",
                font: "16px Arial, Helvetica, sans-serif",
                margin: 5
            };
            $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.color = '#000000';

            $scope.configurationModel.ChartModel.ChartOptions.navigator = { pane: { height: 90, margin: { top: 10 } } };
            $scope.configurationModel.ChartModel.ChartOptions.seriesColors = $.GraphColorList;

            $scope.configurationModel.ChartModel.ChartOptions.theme = "default";

            $scope.configurationModel.ChartModel.ChartOptions.seriesClick = $scope.onSeriesClick;
            
        };

        $scope.setPaginationSearch = function (paginationModel, options) {

            paginationModel.Search = [];

            if (options.data.filter && options.data.filter.filters && options.data.filter.filters.length > 0) {
                for (var i = 0; i < options.data.filter.filters.length; i++) {
                    var value;
                    if (options.data.filter.filters[i].filters) {
                        var search = { Logic: options.data.filter.filters[i].logic };
                        search.Predicates = [];
                        for (var j = 0; j < options.data.filter.filters[i].filters.length; j++) {
                            value = options.data.filter.filters[i].filters[j].value;
                            search.Predicates.push({
                                Key: options.data.filter.filters[i].filters[j].field,
                                Value: value,
                                Operator: options.data.filter.filters[i].filters[j].operator
                            });
                        }
                        paginationModel.Search.push(search);
                    } else {
                        value = options.data.filter.filters[i].value;
                        paginationModel.Search.push({
                            Key: options.data.filter.filters[i].field,
                            Value: value,
                            Operator: options.data.filter.filters[i].operator,
                            Logic: options.data.filter.filters[i].logic
                        });
                    }
                }
            }
        };

        $scope.setPaginationSort = function (paginationModel, options, configurationModel) {

            if (configurationModel.DefaultSortingModel.OrderBy &&
                configurationModel.DefaultSortingModel.OrderBy.length > 0) {
                paginationModel.Sort = [];
                paginationModel.Sort.push({ Code: configurationModel.DefaultSortingModel.OrderBy, Order: configurationModel.DefaultSortingModel.Direction });
            } else {
                paginationModel.Sort = [];
            }
        };

        $scope.getFormatingTemplate = function(type) {
            if (type === "number") {
                return kendo.template($("#kendo-number-label-template").html());
            }
            else if (type === "numberWithQualifier") {
                return kendo.template($("#kendo-number-qualifier-label-template").html());
            }
            else if (type === "decimal1") {
                return kendo.template($("#kendo-decimal-1-label-template").html());
            }
            else if (type === "decimal2") {
                return kendo.template($("#kendo-decimal-2-label-template").html());
            } else {
                return "#= value #";
            }
        };
        

        $scope.findMaxFieldValue=function(chartData,series) {

            var maxValue = 0;
            for (var i = 0; i < series.length; i++) {
                var serie = series[i];
                for (var j = 0; j < chartData.Rows.length; j++) {

                    var row = chartData.Rows[j];
                    if (row[serie.field] && isFinite(row[serie.field])) {
                        var val = Number(row[serie.field]);
                        if (val && val > maxValue) {
                            maxValue = val;
                        }
                    }
                }
            }

            return maxValue;
        };

        $scope.init = function() {
            $scope.configurationModel = chart2ConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.controllingModel = chart2ConfigurationService.getControllingModel();
            $scope.applyBaseChartOptions();
            $scope.seriesDefaults = {};

            // BaseValue check and remove it if its not a number
            if( typeof $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.min !== 'number') {
                delete $scope.configurationModel.ChartModel.ChartOptions.axisDefaults.min;
            }

            // Tooltip aka label on hover
            if ($scope.configurationModel.ChartModel.HoverLabel) {
                $scope.seriesDefaults.tooltip = { visible: true, template:  $scope.getFormatingTemplate($scope.configurationModel.ChartModel.TooltipFormating) };
            }

            // label aka static label
            if ($scope.configurationModel.ChartModel.StaticLabel) {
                $scope.seriesDefaults.labels = { visible: true, font:"bold 12px Arial, Helvetica, sans-serif", template:  $scope.getFormatingTemplate($scope.configurationModel.ChartModel.StaticLabelFormating) };
            }
            // set default color for some chart types
            if ($scope.configurationModel.ChartModel.SeriesDefinitions.length > 0) {

                $scope.seriesDefaults.bar = {
                    color: $scope.configurationModel.ChartModel.SeriesDefinitions[0].SerieColour
                };
                $scope.seriesDefaults.column = {
                    color: $scope.configurationModel.ChartModel.SeriesDefinitions[0].SerieColour
                };
                $scope.seriesDefaults.funnel = {
                    color: $scope.configurationModel.ChartModel.SeriesDefinitions[0].SerieColour
                };
            }

            if ($scope.configurationModel.ChartModel.DisableLegend) {
                $scope.configurationModel.ChartModel.ChartOptions.legend = { visible: false };
            }

            $scope.PaginationModel = {};
            $scope.PaginationModel.CurrentUICulture = kendo.culture().name;
            $scope.PaginationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
            $scope.PaginationModel.WorkflowId = (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
            $scope.PaginationModel.OutputVariable = (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;

            $scope.PaginationModel.DataSourceType = $scope.portlet.DataSourceType;
            $scope.PaginationModel.PortletId = $scope.portlet.Id;
            $scope.PaginationModel.Search = [];
            $scope.PaginationModel.PageSize = $scope.configurationModel.PageSize;
            $scope.PaginationModel.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;
            $scope.PaginationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
            $scope.PaginationModel.StartIndex = 0;
            $scope.PaginationModel.PageNumber = 1;

            if ($scope.configurationModel.DefaultSortingModel.OrderBy && $scope.configurationModel.DefaultSortingModel.OrderBy.length > 0) {
                $scope.PaginationModel.Sort = [];
                $scope.PaginationModel.Sort.push({ Code: $scope.configurationModel.DefaultSortingModel.OrderBy, Order: $scope.configurationModel.DefaultSortingModel.Direction });
            } 
            else {
                $scope.PaginationModel.Sort = [];
            }

            ChartService.getData($scope.PaginationModel,$scope.portlet.Id).then(function (result) {
                if (result.data.Headers.length > 0) {

                    $scope.configurationModel.ChartModel.ChartOptions.categoryAxis = [];
                    var possibleAxis = [];
                    result.data.Headers.forEach(function (currentValue, index, array) {
                        possibleAxis[currentValue.ColumnCode] = {
                            field: currentValue.ColumnCode,
                            name: currentValue.ColumnCode
                        };
                    });

                    $scope.series = [];
                    if ($scope.configurationModel.ChartModel.SeriesDefinitions &&
                        $scope.configurationModel.ChartModel.SeriesDefinitions.length) {
                        $scope.configurationModel.ChartModel.SeriesDefinitions.forEach(function (currentValue, index, array) {
                                        
                            var serie = {
                                field: currentValue.Field,
                                color: currentValue.SerieColour,
                                name: currentValue.DisplayName,
                                categoryAxis: currentValue.Category,
                                categoryField: currentValue.Category,
                                type: currentValue.Type
                            };

                            if ($scope.configurationModel.ChartModel.SteppedLines) {
                                serie.style = "step";
                            }

                            $scope.series.push(serie);

                            if (-1 === $.inArray(possibleAxis[currentValue.Category], $scope.configurationModel.ChartModel.ChartOptions.categoryAxis)) {
                                $scope.configurationModel.ChartModel.ChartOptions.categoryAxis
                                    .push(possibleAxis[currentValue.Category]);
                            }
                        });
                    }
                    $scope.chartData = new kendo.data.DataSource({
                        pageSize: $scope.PaginationModel.PageSize,
                        transport: {
                            read: function (options) {

                                $scope.setPaginationSort($scope.PaginationModel, options, $scope.configurationModel);
                                $scope.setPaginationSearch($scope.PaginationModel, options);

                                ChartService.getChartData($scope.PaginationModel, $scope.portlet.Id)
                                    .then(function (result) {
                                        options.success(result.data);
                                        var maxValue = $scope.findMaxFieldValue(result.data, $scope.series);
                                        if (maxValue > 0) {
                                            $scope.chart.setOptions({
                                                valueAxis: { max: Math.ceil(maxValue * 1.1) }
                                            });
                                        }

                                    }).catch(function(error) {
                                        options.error(error);
                                    }).finally(function() {
                                        $scope.controllingModel.DisplayLoadingBar = false;
                                    });
                            }
                        },
                        schema: {
                            data: "Rows",
                            total: "Total",
                            groups: "Groups",
                            model: {
                                id: "rnum",
                                fields: $scope.createFields(result.data.Headers)
                            }
                        },
                        serverPaging: true,
                        serverFiltering: true,
                        serverSorting: true,
                        serverGrouping: true
                    }
                    );
                };
            }).catch(angular.noop).finally(
                function() {
                    $scope.controllingModel.DisplayLoadingBar = false;                  
                }
            );

              


        };

$scope.init();

}
]);
