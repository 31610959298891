app.controller('GenericTreeController', ['$scope', '$rootScope', 'GenericTreeService', 'genericTreeConfigurationService', 'receiverService',
    'globalVariableService', 'portletErrorService', 'appConfig', '$controller', 
    function ($scope, $rootScope, GenericTreeService, genericTreeConfigurationService, receiverService, globalVariableService,
        portletErrorService, appConfig, $controller) {

        $controller("BasePluginController", { $scope: $scope });

        $scope.selectItem = function(item) {
            var data = {};
            data[$scope.configurationModel.Node] = item.Id;
            $rootScope.$broadcast("onGenericTreeNodeSelected", { SenderId: $scope.portlet.Id, Data: data });
        };

        $scope.refresh = function() {
            $scope.configurationModel.RefreshWorkflowCache = true;
            $scope.configurationModel.Id = null;
            $scope.tree.dataSource.read();
            $rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
        };

        $scope.newRowFilter = function(rowFilter) {
            if (rowFilter) {

                var startNode = $scope.configurationModel.StartNodeValue.trim();

                if (startNode.substr(0, 1) === "{") {
                    startNode = startNode.replace("{", "").replace("}", "");
                    filterValue = rowFilter[startNode];
                    startNode = filterValue ? filterValue.toString() : "";
                }

                $scope.configurationModel.StartNodeValue = startNode;
            }
        };

        $scope.$on("onRowSelected",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                    $scope.initConfigurationModel();
                    $scope.newRowFilter(data.Data);
                    $scope.refresh();
                }
            });

        $scope.$on("onRefresh",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                    $scope.refresh();
                }
            });

        $scope.$on("onGenericTreeNodeSelected",
            function(event, data) {
                if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                    $scope.initConfigurationModel();
                    $scope.newRowFilter(data.Data);
                    $scope.refresh();
                }
            });

        $scope.initConfigurationModel = function() {
            $scope.configurationModel =
                genericTreeConfigurationService.getConfigurationModel($scope.portlet.Properties);
            $scope.configurationModel.QueryId = (_.first($scope.portlet.Queries) || { Id: null }).Id;
            $scope.configurationModel.WorkflowId =
                (_.first($scope.portlet.Workflows) || { WorkflowId: null }).WorkflowId;
            $scope.configurationModel.OutputVariable =
                (_.first($scope.portlet.Workflows) || { OutputVariable: null }).OutputVariable;
            $scope.configurationModel.DataSourceType = $scope.portlet.DataSourceType;
            $scope.configurationModel.GlobalVariables = globalVariableService.getAvailableGlobalVariables();
        }


        $scope.treeOptions = {
            dataBound: function (e) {
                var refreshButon = jQuery(e.sender.element).siblings("span.k-icon.k-i-refresh");
                var parentPc = jQuery(e.sender.element).parents("div.portletContainer");
                var hasParentPcHeader = jQuery(parentPc).find('div.portletContainerHeader').is(":visible");
                var hasPortletHeader = $scope.configurationModel.isTitleEnabled;
                var pcTabs = jQuery(parentPc).find("div.portletContainerContent ul.nav.nav-tabs");
                var hasPcTabs = pcTabs.length ? true : false;
           
                if (!hasParentPcHeader && !hasPortletHeader && !hasPcTabs) {
                    refreshButon.removeClass('generic-refresh-button');
                    refreshButon.addClass('generic-refresh-button-moved-left');
                }
            }
        };


        $scope.initialize = function () {

            $scope.controllingModel = genericTreeConfigurationService.getControllingModel();

            $scope.initConfigurationModel();

            $scope.treeData = new kendo.data.HierarchicalDataSource({
                transport: {
                    read: function(options) {

                        $scope.controllingModel.DisplayLoadingBar = true;

                        var startNode = $scope.configurationModel.StartNodeValue.trim();

                        if (startNode.substr(0, 1) === "{") {
                            $scope.controllingModel.DisplayLoadingBar = false;
                            $scope.controllingModel.RefreshWorkflowCache = false;
                            options.success([]);
                        } else {

                            if (options.data.Id) {
                                $scope.configurationModel.Id = options.data.Id;
                            }
                            GenericTreeService.getNodes($scope.configurationModel, $scope.portlet.Id)
                                .then(function(result) {
                                    options.success(result.data);
                                    $scope.initialized = true;
                                    
                                }).catch(function() {
                                    options.success([]);
                                }).finally(function() {
                                    $scope.controllingModel.RefreshWorkflowCache = false;
                                    $scope.controllingModel.DisplayLoadingBar = false;
                                });
                        }
                    }
                },
                schema: {
                    model: {
                        id: "Id",
                        hasChildren: "HasChildren"
                    }
                }
            });
        };

        $scope.initialize();

}])