app.controller("MapController",
    [
        "$scope", "$controller", "mapService", "$timeout", "pluginConfigurationService", "leafletData",
        "receiverService", "$rootScope", "leafletMarkerEvents", "leafletMapEvents", "leafletBoundsHelpers",
        "portletErrorService", 'propertyService', 'imageService',
        function($scope,
            $controller,
            mapService,
            $timeout,
            pluginConfigurationService,
            leafletData,
            receiverService,
            $rootScope,
            leafletMarkerEvents,
            leafletMapEvents,
            leafletBoundsHelpers,
            portletErrorService,
            propertyService,
            imageService) {

            $controller('BasePluginController', { $scope: $scope });
            $controller('OperationAndInputExecutionController', { $scope: $scope });

            /**
             * Portal events
             */
            $scope.$on("portletTabChanged",
                function(event, data) {
                    if (data && data.portlet && $scope.portlet.Id === data.portlet) {
                        $scope.fitToView();
                    }
                });

            $scope.$on("onMaximized",
                function(event, data) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].Id === $scope.portlet.Id) {
                            $scope.fitToView();
                            break;
                        }
                    }
                });

            $scope.$on("onMinimized",
                function(event, data) {
                    for (var i = 0; i < data.length; i++) {
                        if (data[i].Id === $scope.portlet.Id) {
                            $scope.fitToView();
                            break;
                        }
                    }
                });


            $scope.$on("resize",
                function(event, data) {
                    if (data) {
                        for (var i = 0; i < data.PortletIds.length; i++) {
                            if (data.PortletIds[i] === $scope.portlet.Id) {
                                $scope.fitToView();
                                break;
                            }
                        }
                    } else {
                        $scope.fitToView();
                    }
                });

            /**
             * Leaflet events
             */
            $scope.$on('leafletDirectiveMap.layeradd',
                function(e, args) {
                    leafletData.getMarkers().then(function(markers) {
                        var keys = _.keys(markers);
                        if ($scope.configurationModel.TooltipConfiguration.Tooltip) {
                            keys.forEach(function(k) {
                                if (!markers[k].options.hasBoundedTooltip) {
                                    markers[k].bindTooltip(markers[k].options.tooltip);
                                    markers[k].options.hasBoundedTooltip = true;
                                }
                            });
                        }
                    }).catch(function () {});
                });

            $scope.$on('leafletDirectiveMarker.dragend',
                function(e, args) {

                    var newLangLong = args.leafletObject.getLatLng();

                    var operationInputData = {
                        OperationType: 'DragAndDrop',
                        NewLatitude: newLangLong.lat,
                        NewLongitude: newLangLong.lng,
                        Id: args.model.id
                    };
                    var data = args.model.data;

                    if ($scope.configurationModel.FlowConfiguration.DropMarker &&
                        ($scope.configurationModel.FlowConfiguration.DropMarker !== -1)) {
                        $scope.runFlow($scope.configurationModel.FlowConfiguration.DropMarker.Id,
                            !$scope.configurationModel.FlowConfiguration.DropMarker.IsMachineWorkflow,
                            data,
                            operationInputData);
                    } else {
                        portletErrorService.showError('Please set Marker Drag and Drop workflow!', $scope.portlet.Id);
                    }
                });

            $scope.$on('leafletDirectiveMarker.click',
                function(e, args) {
                    if ($scope.clicked) {
                        $scope.cancelClick = true;
                        return;
                    }
                    $scope.clicked = true;

                    $timeout(function() {
                            if ($scope.cancelClick) {
                                $scope.cancelClick = false;
                                $scope.clicked = false;
                                return;
                            }
                            var selectedIndex = args.modelName;
                            var marker = $scope.markers[selectedIndex];
                            $scope.selectMarker(marker);

                            $scope.cancelClick = false;
                            $scope.clicked = false;

                        },
                        300);
                });

            $scope.$on('leafletDirectiveMarker.dblclick',
                function(e, args) {
                    $timeout(function() {

                        var selectedIndex = args.modelName;
                        var marker = $scope.markers[selectedIndex];
                        $scope.runDblClickWorkflow(marker);
                    });
                });

            $scope.selectMarker = function(elem) {
                $scope.selectedMarker = elem;
                $rootScope.$broadcast("onRowSelected", { SenderId: $scope.portlet.Id, Data: elem.data });
            };

            /**
             *  LISTENING FOR EVENTS FROM OTHER PORTLETS
             */

            $scope.$on("onRowSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRowSelected", data)) {
                        $scope.DataModel.RowFilter = data.Data;
                        $scope.refresh(false, true);
                    }
                });

            $scope.$on("onRefresh",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onRefresh", data)) {
                        $scope.refresh(false, true);
                    }
                });

            $scope.$on("onFilter",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onFilter", data)) {

                        $scope.DataModel.Search = data.Data;
                        $scope.refresh();
                    }
                });

            $scope.$on("onGenericTreeNodeSelected",
                function(event, data) {
                    if (receiverService.canReceive($scope.portlet, "onGenericTreeNodeSelected", data)) {
                        $scope.DataModel.RowFilter = data.Data;
                        $scope.refresh(false, true);
                    }
                });

            /**
             * METHODS
             */
            $scope.runCustomFlow = function(workflowIdentifier, isMachineWorkflow) {
                var data = [];
                if ($scope.selectedMarker)
                    data.push($scope.selectedMarker.data);

                $scope.runFlow(workflowIdentifier, !isMachineWorkflow, data);
            };

            $scope.runDblClickWorkflow = function(elem) {
                var operationInputData = {
                    OperationType: 'DoubleClick',
                    Latitude: elem.lat,
                    Longitude: elem.lng,
                    Id: elem.id
                };

                var data = [];
                data.push(elem.data);

                if ($scope.configurationModel.FlowConfiguration.DoubleClickMarker &&
                    ($scope.configurationModel.FlowConfiguration.DoubleClickMarker !== -1))
                    $scope.runFlow($scope.configurationModel.FlowConfiguration.DoubleClickMarker.Id,
                        !$scope.configurationModel.FlowConfiguration.DoubleClickMarker.IsMachineWorkflow,
                        data,
                        operationInputData);
            };

            $scope.customFlowDisabled = function(flowConfigId) {
                if (flowConfigId) {
                    return mapService.isCustomFlowDisabled($scope.configurationModel,
                        flowConfigId,
                        $scope.selectedMarker);
                }
                return true;
            };

            $scope.refresh = function(withOverlays, clearCenter) {

                leafletData.getMap().then(function(map) {

                    if (!clearCenter) {
                        $scope.lastKnownCenter = map.getCenter();
                        $scope.lastKnownZoom = map.getZoom();
                    } else {
                        $scope.lastKnownCenter = null;
                        $scope.lastKnownZoom = null;
                    }

                    $scope.DataModel.RefreshWorkflowCache = true;
                    $rootScope.$broadcast("onRefresh", { SenderId: $scope.portlet.Id });
                    $scope.getData(withOverlays);

                }).catch(function () { });
            };


            $scope.paths = [];

            $scope.tiles = {
                openstreetmap: {
                    //url:"http://tile.memomaps.de/tilegen/{z}/{x}/{y}.png",
                    url: "http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    options: {
                        attribution:
                            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    }
                }
            };

            $scope.recalculateHeight = function() {

                var pcBodyHandler = $("#map" + $scope.portlet.Id).closest(".portletContainerBody");
                $scope.mapPortletHeight = pcBodyHandler.outerHeight(true) -
                    pcBodyHandler.find(".map-toolbar").outerHeight(true) -
                    pcBodyHandler.find(".real-header").outerHeight(true) -
                    pcBodyHandler.find(".portletContainerHeader").outerHeight(true);

                if ($scope.portletContainer.Layout === 'Tab' && $scope.portletContainer.Portlets.length > 1) {
                    $scope.mapPortletHeight = pcBodyHandler.outerHeight(true) -
                        pcBodyHandler.find(".map-toolbar").outerHeight(true) -
                        pcBodyHandler.find(".nav.nav-tabs").outerHeight(true) -
                        pcBodyHandler.find(".real-header").outerHeight(true) -
                        pcBodyHandler.find(".portletContainerHeader").outerHeight(true);
                }
                if (propertyService.getBoolPropertyValue($scope.portlet.Properties, 'isTitleEnabled')) {
                    $scope.mapPortletHeight.height -= 74;
                }
            };

            //one function to recalculate color, fill color and width per feature 
            $scope.prepareStyle = function(feature) {

                var featureStyle = {
                    color: $scope.getColor(feature),
                    fillColor: $scope.getColor(feature, 'FillColor'),
                    weight: 3
                };

                return {
                    fillColor: featureStyle.fillColor,
                    weight: 3,
                    opacity: 1,
                    color: featureStyle.color,
                    dashArray: '3',
                    fillOpacity: 0.7
                };
            }

            $scope.getColor = function(feature, colorField) {

                var elem = { Data: feature.OverlayData }
                _.extend(elem.Data, feature.properties);

                var color = null;

                if (colorField) {
                    color = mapService.calculateColor(elem,
                        _.sortBy($scope.configurationModel.OverlaysColorRulesConfiguration.ColorRules,
                            function(item) {
                                return item.Position;
                            }),
                        $scope.configurationModel.OverlaysColorRulesConfiguration.DefaultColor,
                        colorField);
                } else {
                    color = mapService.calculateColor(elem,
                        _.sortBy($scope.configurationModel.OverlaysColorRulesConfiguration.ColorRules,
                            function(item) {
                                return item.Position;
                            }),
                        $scope.configurationModel.OverlaysColorRulesConfiguration.DefaultColor);
                }

                return color;
            }

            $scope.calculateOverlays = function(overlays) {

                if (overlays) {

                    var layers = [];

                    leafletData.getMap().then(function(map) {
                        for (var i = 0; i < overlays.length; i++) {
                            var parsed = JSON.parse(overlays[i].GeoJSON);
                            var overlay = overlays[i];

                            for (var j = 0; j < parsed.features.length; j++) {
                                parsed.features[j].OverlayData = overlay.Data;
                            }

                            L.geoJSON(parsed,
                                {
                                    style: $scope.prepareStyle,
                                    pointToLayer: function (feature, latlng) {
                                        return L.circleMarker(latlng, {
                                            radius: 5,
                                            color: $scope.getColor(feature),
                                            fillColor: $scope.getColor(feature, 'FillColor'),
                                            weight: 3,
                                            opacity: 1,
                                            fillOpacity: 0.8
                                        });
                                    }
                                }).addTo(map);
                        };
                    }).catch(function () { });
                }
            };

            var redraw = function() {

                $scope.recalculateHeight();
                $scope.calculateOverlays($scope.overlaysResponse.data.Overlays);
                $scope.markers = mapService.getMapData($scope.response.data, $scope.configurationModel);

                var mapZoom = $scope.configurationModel.DefaultViewConfiguration.ZoomLevel;
                if ($scope.configurationModel.DefaultViewConfiguration.RememberCurrentState) {

                    if ($scope.lastKnownZoom)
                        mapZoom = $scope.lastKnownZoom;
                }
                $scope.bounds = L.latLngBounds([
                    $scope.markers.map(function(m) {
                        return [m.lat, m.lng];
                    })
                ]);

                // Wait for center to be established
                leafletData.getMap().then(function(map) {
                    map.invalidateSize();

                    switch ($scope.configurationModel.DefaultViewConfiguration.DisplayMode) {
                    case 'Default':
                        if ($scope.markers.length) {

                            if ($scope.lastKnownCenter &&
                                $scope.configurationModel.DefaultViewConfiguration.RememberCurrentState)
                                map.setView($scope.lastKnownCenter, mapZoom);
                            else
                                map.fitBounds($scope.bounds);
                        } else {
                            navigator.geolocation.getCurrentPosition(function(location) {
                                    $scope.lfCenter = {
                                        lat: location.coords.latitude,
                                        lng: location.coords.longitude,
                                        zoom: mapZoom
                                    };
                                },
                                function(error) {
                                    portletErrorService.showError(error.message, $scope.portlet.Id);
                                });
                        }
                        break;
                    case 'Browser':
                        navigator.geolocation.getCurrentPosition(function(location) {
                                $scope.lfCenter = {
                                    lat: location.coords.latitude,
                                    lng: location.coords.longitude,
                                    zoom: mapZoom
                                };
                            },
                            function(error) {
                                portletErrorService.showError(error.message, $scope.portlet.Id);
                            });
                        break;
                    case 'Manual':
                        $scope.lfCenter = {
                            lat: $scope.configurationModel.DefaultViewConfiguration.Latitude,
                            lng: $scope.configurationModel.DefaultViewConfiguration.Longitude,
                            zoom: mapZoom
                        };
                        break;
                    }
                }).catch(function () { });

                //$scope.paths = mapService.getPaths($scope.response.data, $scope.configurationModel);

            };
            $scope.getOverlays = function() {

                $scope.controllingModel.DisplayLoadingBar = true;

                mapService.getOverlays($scope.DataModel, $scope.portlet.Id).then(
                    function(overlaysResponse) {

                        $scope.overlaysResponse = overlaysResponse;
                        redraw();
                    }).catch(function(err) {
                    console.log(err);
                }).finally(function() {
                    $scope.controllingModel.DisplayLoadingBar = false;
                });
            }


            $scope.getData = function(withOverlays) {

                $scope.controllingModel.DisplayLoadingBar = true;

                mapService.getMarkers($scope.DataModel, $scope.portlet.Id).then(
                    function(response) {

                        $scope.markers = []; //this is important hack, do not remove!
                        $scope.response = response;

                        if (withOverlays)
                            $scope.getOverlays();
                        else {
                            $timeout(function() {
                                redraw();
                            });
                        }

                    }).catch(function(err) {
                    console.log(err);
                }).finally(function() {
                    $scope.controllingModel.DisplayLoadingBar = false;
                });
            };

            //This method is a hack, but no idea how enforce fitting map to view in other way.
            $scope.fitToView = function() {
                redraw();
            };

            $scope.hasTopBars = function() {
                switch ($scope.portletContainer.Layout) {
                case "Tab":
                    return !($scope.portletContainer.Portlets.length === 1 &&
                        $scope.portletContainer.IsHeaderDisabled &&
                        !$scope.configurationModel.isTitleEnabled);
                default:
                    return true;
                }
            };


            $scope.initialize = function() {

                $scope.categories = {};
                $scope.lfCenter = {};

                $scope.configurationModel =
                    pluginConfigurationService.getConfigurationModel($scope.portlet.Configuration, "Map");
                $scope.controllingModel = pluginConfigurationService.getControllingModel();
                $scope.DataModel = {};
                $scope.DataModel.CurrentUICulture = kendo.culture().name;
                $scope.DataModel.QueryId = $scope.portlet.Queries.length > 0 ? $scope.portlet.Queries[0].Id : null;

                $scope.DataModel.WorkflowId =
                    $scope.portlet.Workflows.length > 0 ? $scope.portlet.Workflows[0].WorkflowId : null;
                $scope.DataModel.OutputVariable = $scope.portlet.Workflows.length > 0
                    ? $scope.portlet.Workflows[0].OutputVariable
                    : null;

                $scope.DataModel.OverlaysWorkflowId = $scope.configurationModel.FlowConfiguration.OverlaysDataSource
                    ? $scope.configurationModel.FlowConfiguration.OverlaysDataSource.Id
                    : null;
                $scope.DataModel.OverlaysOutputVariable = $scope.configurationModel.FlowConfiguration.OverlaysDataSource
                    ? $scope.configurationModel.FlowConfiguration.OverlaysDataSource.OutputVariable
                    : null;

                $scope.DataModel.RefreshWorkflowCache = true;
                $scope.DataModel.DataSourceType = $scope.portlet.DataSourceType;
                $scope.DataModel.PortletId = $scope.portlet.Id;
                $scope.DataModel.Search = [];
                $scope.DataModel.IgnoreExecutionCheck = $scope.configurationModel.IgnoreExecutionCheck;
                $scope.markers = [];
                $scope.selectedMarker = null;

                $scope.selectedZoom = $scope.configurationModel.DefaultViewConfiguration.ZoomLevel;
                $scope.isProgramaticZoom = false;

                $timeout(function() {
                    $scope.recalculateHeight();
                    $scope.legend = mapService.getLegend($scope.configurationModel).map(function(l) {
                        l.IconPath = imageService.getImageInSize(l.Icon, 25);
                        return l;
                    });
                });
                $scope.getData(true);

            }();

            /**
             * When map is loaded allow do drag&drop from legend to map
             */
            $timeout(function() {
                var posTop = $('.draggable-marker').css('top');
                var posLeft = $('.draggable-marker').css('left');

                $('.draggable-marker').draggable({
                    stop: function(e, ui) {

                        //1. Drag & drop handling
                        $('.draggable-marker').css('top', posTop);
                        $('.draggable-marker').css('left', posLeft);

                        var leafletMapHandler =
                            window.jQuery("#map" + $scope.portlet.Id).find(".map.angular-leaflet-map");

                        var coordsX = e.pageX - leafletMapHandler.offset().left - 15;
                        var coordsY = e.pageY - leafletMapHandler.offset().top - 15;

                        if (coordsX < 0 || coordsY < 0) {
                            return;
                        }

                        var point = L.point(coordsX, coordsY);

                        var markerCoords = null;
                        leafletData.getMap().then(function(map) {

                            markerCoords = map.containerPointToLatLng(point);

                            var tmpMarker = {
                                id: null,
                                lat: markerCoords.lat,
                                lng: markerCoords.lng,
                                focus: true,
                                draggable: true,
                                icon: {
                                    type: 'div'
                                },
                                Data: {
                                    Type: ui.helper.context.getAttribute("data-marker-type")
                                },
                                tooltip: "New marker"
                            };

                            tmpMarker.icon.html = null;

                            switch (ui.helper.context.tagName) {
                            case "IMG":
                                tmpMarker.icon.html =
                                    '<img src="' + ui.helper.context.src + '" width="48" height="48"></img>';
                                break;
                            }

                            $scope.markers.push(tmpMarker);

                            //2. Executing workflow
                            var operationInputData = {
                                OperationType: 'CreateNewMarker',
                                NewLatitude: tmpMarker.lat,
                                NewLongitude: tmpMarker.lng,
                                Id: 0,
                                MarkerType: tmpMarker.Data.Type
                            };

                            var data = [];

                            if ($scope.configurationModel.FlowConfiguration.CreateNewMarker &&
                                ($scope.configurationModel.FlowConfiguration.CreateNewMarker !== -1)) {
                                $scope.runFlow($scope.configurationModel.FlowConfiguration.CreateNewMarker.Id,
                                    !$scope.configurationModel.FlowConfiguration.CreateNewMarker.IsMachineWorkflow,
                                    data,
                                    operationInputData);
                            } else {
                                portletErrorService.showError('Please set Create new Marker workflow!',
                                    $scope.portlet.Id);
                            }
                        }).catch(function () { });
                    }
                });
            });
        }
    ]);